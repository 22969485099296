import NotificationIcon from '@core/assets/icons/notification.svg';

import { useQuery, useInfiniteQuery } from 'react-query';
import { useMemo } from 'react';

import Popover from '@core/components/popover/Popover';
import usePopover from 'hooks/use-popover';

import { GetNotificationService } from 'services/notification';
import EmptyList from '../notificationcenter/EmptyList';
import NotificationList from '../notificationcenter/NotificationList';
import IconButton from '@core/components/iconbutton/IconButton';

import classes from './Notification.module.scss';
import { getAccessToken } from 'utils/authentication';

const Notification = ({newNotificationCount}) => {
	const { data, isLoading, error, fetchNextPage, isFetchingNextPage, hasNextPage, refetch } = useInfiniteQuery(['user-notification'],
        ({ pageParam = 1 }) => GetNotificationService({ page: pageParam }),
        {
            getNextPageParam: (lastpage, pages) => {
                if (lastpage.data?.next) {
                    return pages.length + 1;
                }
                return;
            },
        }
    );
    const notificationArray = data?.pages?.reduce((finalArray, currentPage) => {
        if (currentPage?.data?.results) {
            return [...finalArray, ...currentPage.data.results];
        } else {
            return finalArray;
        }
    }, []);
	const { show, destroyPopover, showPopover } = usePopover();

	const scrollBottomHandler = () =>{
		if(hasNextPage)
			fetchNextPage();
	};	

	return (
		<div className={classes['notificaton-container']}>
			<IconButton badge={newNotificationCount} onClick={showPopover} data-testid="notifBtn">
				<NotificationIcon role="img" />{' '}
			</IconButton>
			<Popover
				show={show}
				closeHandler={destroyPopover}
				title="Notifications"
				type="mobile-fullscreen"
				scrollBottomHandler={scrollBottomHandler}
				>
				{data && !isLoading && notificationArray.length > 0 ? (
					<NotificationList
						data={notificationArray}
						closePopover={destroyPopover}
						refetchHandler={refetch}
					/>
				) : (
					<EmptyList />
				)}
			</Popover>
		</div>
	);
};

export default Notification;
