export const planOptions = {
    free: [
        {
            id: 1,
            description: 'Access to free courses'
        },
        {
            id: 2,
            description: 'Limited access to skill tree'
        },
        {
            id: 3,
            description: 'Certification for free courses'
        },
        {
            id: 4,
            description: 'Explore job board'
        },
        {
            id: 5,
            description: 'Join our LinkedIn community'
        },
    ],
    premium: [
        {
            id: 1,
            description: 'Access full content library'
        },
        {
            id: 2,
            description: 'Course certifications'
        },
        {
            id: 3,
            description: 'Access CV builder'
        },
        {
            id: 4,
            description: 'Access cover letter builder'
        },
        {
            id: 5,
            description: 'Full access to skill tree'
        },
        {
            id: 6,
            description: 'Explore job board'
        },
        {
            id: 7,
            description: 'Access job application board'
        },
        {
            id: 8,
            description: 'Personalized profile URL'
        },
        {
            id: 9,
            description: 'Pro only community and events'
        },
    ],

}