import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import { CompanyDashboardPages } from 'constants/company';

import DasboardIcon from 'assets/icons/chart-2.svg';
import JobpostIcon from 'assets/icons/book.svg';
import CompanyInfoIcon from 'assets/icons/clipboard-text.svg';
import UserManagementIcon from 'assets/icons/profile-2user-icon.svg';
import DepartmentManagementIcon from 'assets/icons/people.svg';
import BillingIcon from 'assets/icons/receipt-2.svg';

import classes from './CompanyMenu.module.scss';

const CompanyMenu = ({
	accessible_items = [],
	company_membership
}) => {
	const router = useRouter();

	useEffect(() => {
		if (router.isReady) {
			if (!accessible_items.includes(CompanyDashboardPages.find(page => page.url === router.pathname)?.name) && company_membership == 'active')
				router.replace('/company/dashboard')
		}
	}, [router]);

	return (
		<div className={`${classes['company-menu-wrapper']} d-flex flex-column`}>
			{(accessible_items.includes('dashboard') || company_membership !== 'active') && (
				<button
					disabled={!accessible_items.includes('dashboard')}
					onClick={() => router.push('/company/dashboard')}
					className={`${(router.pathname === '/company/dashboard' || router.pathname === '/company/dashboard/applicants-details/[id]') ? classes['active-menu'] : ''}`}>
					<DasboardIcon />
					Dashboard
				</button>
			)}
			{(accessible_items.includes('job_posts') || company_membership !== 'active') && (
				<button
					disabled={!accessible_items.includes('job_posts')}
					onClick={() => router.push('/company/dashboard/job-posts-list')}
					className={`${router.pathname === '/company/dashboard/job-posts-list' ? classes['active-menu'] : ''}`}>
					<JobpostIcon />
					Job posts
				</button>
			)}
			{(accessible_items.includes('company_account') || company_membership !== 'active') && (
				<button
					disabled={!accessible_items.includes('company_account')}
					onClick={() => router.push('/company/dashboard/account')}
					className={`${router.pathname === '/company/dashboard/account' ? classes['active-menu'] : ''}`}>
					<CompanyInfoIcon />
					Company Account
				</button>
			)}
			{(accessible_items.includes('user_management') || company_membership !== 'active') && (
				<button
					disabled={!accessible_items.includes('user_management')}
					onClick={() => router.push('/company/dashboard/user-management')}
					className={`${router.pathname === '/company/dashboard/user-management' ? classes['active-menu'] : ''}`}>
					<UserManagementIcon />
					User Management
				</button>
			)}
			{(accessible_items.includes('departments') || company_membership !== 'active') && (
				<button
					disabled={!accessible_items.includes('departments')}
					onClick={() => router.push('/company/dashboard/departments')}
					className={`${router.pathname === '/company/dashboard/departments' ? classes['active-menu'] : ''}`}>
					<DepartmentManagementIcon />
					Departments
				</button>
			)}
			{(accessible_items.includes('billing_information') || company_membership !== 'active') && (
				<button
					disabled={!accessible_items.includes('billing_information')}
					onClick={() => router.push('/company/dashboard/payment')}
					className={`${router.pathname === '/company/dashboard/payment' ? classes['active-menu'] : ''}`}>
					<BillingIcon />
					Payment & Billing information
				</button>
			)}
		</div>
	);
};

export default CompanyMenu;
