import { forwardRef } from 'react';

import { NORMAL, MOBILE_FULLSCREEN, MOBILE_SLIDE_UP, CONFIRMATION } from '@core/constants/ModalTypes';

import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';

const Animation = forwardRef((props, ref) => {
	const fadeAnimation = () => <Fade {...props} ref={ref} />;
	const slideAnimation = () => (
		<Slide {...props} ref={ref} direction="up" mountOnEnter unmountOnExit />
	);

	const modalTypes = {		
		[NORMAL]: fadeAnimation(),
		[MOBILE_FULLSCREEN]: slideAnimation(),
		[MOBILE_SLIDE_UP]: slideAnimation(),
		[CONFIRMATION]: fadeAnimation(),
	};

	return modalTypes[props.type];
});

Animation.displayName = 'Animation';

export default Animation;
