import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import useLocalStorage from "use-local-storage";

import Head from 'next/head';

import LayoutWrapper from '@core/layouts/layout/LayoutWrapper';
import RouterGuard from 'router/RouterGuard';
import FallBackSpinner from '@core/components/fallbackspinner/Fallback-spinner';
import CookieConsent from '@core/components/cookiemodal/CookieConsent';

import UseAffiliateCode from 'hooks/use-affiliatecode';
import UseVisitor from 'hooks/use-visitor';


// firebase provider
// import '../firebase';

// * redux store
import { Provider } from 'react-redux';
import store from 'store/index';

// ** React Query Provider & config
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query';
import { queryClientConfig } from '@core/configs/queryClientConfig';

// Material Ui theme provider
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '@core/theme';
import { darkTheme } from '@core/theme/darkMode';

// * Global bootstrap
import 'bootstrap/dist/css/bootstrap.css';

// * Global fonts
import '@core/styles/fonts.scss';

import 'styles/globals.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// animation css
import "animate.css/animate.compat.css";


export default function App({ Component, pageProps }) {
	const [queryClient] = useState(() => new QueryClient(queryClientConfig));
	const router = useRouter();
	const [loading, setLoading] = useState(false);
	const [themeName, setThemeName] = useLocalStorage("themeName", "");
	const [themeNameState, setThemeNameState] = useState();
	useEffect(() => {
		setThemeNameState(themeName);
	}, [themeName])
	useEffect(() => {
		require('bootstrap/dist/js/bootstrap.bundle.min');
		require('bootstrap/dist/css/bootstrap.min.css');
		require('../firebase');
		// Push a virtual pageview on route change for GTM
		const handleRouteCompleteChange = (url) => {
			window.dataLayer.push({
				event: 'pageview',
				pagePath: url
			});
			setLoading(false);
		};

		const handleRouteChangeStart = (url, { shallow }) => {
			if (!shallow) {
				setLoading(true);
			}
		};

		router.events.on('routeChangeStart', handleRouteChangeStart);
		router.events.on('routeChangeComplete', handleRouteCompleteChange);
		router.events.on('routeChangeError', () => setLoading(false));

		return () => {
			router.events.off('routeChangeStart', () => handleRouteChangeStart);
			router.events.off('routeChangeComplete', handleRouteCompleteChange);
			router.events.off('routeChangeError', () => setLoading(false));
		};
	}, []);
	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>

			<QueryClientProvider client={queryClient}>
				<Hydrate state={pageProps.dehydratedState}>
					<Provider store={store}>
						<ThemeProvider theme={defaultTheme}>
							<RouterGuard />
							<UseAffiliateCode />
							<UseVisitor />
							{(!loading) ? (
								// <div data-theme={themeName}>
									<LayoutWrapper>
										<Component {...pageProps} />
										<CookieConsent />
									</LayoutWrapper>
								// </div>
							) : (
								<FallBackSpinner />
							)}
						</ThemeProvider>
					</Provider>
				</Hydrate>
			</QueryClientProvider>
		</>



	);
	// return <Component {...pageProps} />;
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
// 	<React.StrictMode>
// 		<QueryClientProvider client={queryClient}>
// 			<Provider store={store}>
// 				<ThemeProvider theme={defaultTheme}>
// 					<FpjsProvider
// 						loadOptions={{
// 							apiKey: '5KpR0Xo1UVT5quOxOc5y'
// 						}}>
// 						<Suspense fallback={<FallBackSpinner />}>
// 							<LazyApp />
// 						</Suspense>
// 					</FpjsProvider>
// 				</ThemeProvider>
// 			</Provider>
// 		</QueryClientProvider>
// 	</React.StrictMode>
// );
