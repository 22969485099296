export const queryClientConfig = {
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnMount: 'true', // when a component mount and the query already chached , data will be refetched *2
			refetchOnWindowFocus: false, // similar to refetchOnMount, but whenever use focus on window again , it refetch
			refetchOnReconnect: 'always', // user lose connection and reconnect, fetch again
			cacheTime: 1000 * 1, //30 seconds // after this time, unusable cached data will be purged *1
			// cacheTime: 0, //30 seconds // after this time, unusable cached data will be purged *1
			refetchInterval: false, // We may want our application refetching queries at certain interval in time regardless of whether data is stale or not.
			refetchIntervalInBackground: false, // control the refetchInterval behavior for when users are not focused on our application
			suspense: false, // if true active queries will suspend and will throw a runtime error if an error occurs.
			staleTime: 1000 * 1 // default is 0 , can be set to infinity
			// staleTime: 0 // default is 0 , can be set to infinity
		},
		mutations: {
			retry: false
		}
	}
};

//*1 chached Data are Availible after component unmounted
//*2 true , false , always , true cares about staleTime to refetch and if a data is not stale, it would not request again
