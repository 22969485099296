import classes from './CookieConsent.module.scss';

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import ActionButton from '../actionbutton/ActionButton';
import LinkButton from '../linkbutton/LinkButton';
import { IconButton } from '@mui/material';

import CloseIcon from 'assets/icons/close-icon.svg';

export const GDPR_CONSENT = 'gdpr-consent';
export const GDPR_EXPIRE = 'gdpr-expire-time';
export const GDPR_EXPIRE_YEAR = 1;

const CookieConsent = () => {
	const [show, setShow] = useState(false);
	const router = useRouter();
	let origin = '';

	if (typeof window !== 'undefined') {
		origin = window.location.origin;
	}
	useEffect(() => {
		const now = new Date().getTime();
		if (router.isReady) {
			if (typeof window !== 'undefined') {
				const expire = new Date(localStorage.getItem(GDPR_EXPIRE)).getTime();
				!!localStorage.getItem(GDPR_CONSENT) && expire > now ? setShow(false) : setShow(true);
			}
		}
	}, [router.isReady]);

	const consentHandler = () => {
		const date = new Date();
		date.setFullYear(date.getFullYear() + GDPR_EXPIRE_YEAR);
		if (typeof window !== 'undefined') {
			localStorage.setItem(GDPR_CONSENT, 'true');
			localStorage.setItem(GDPR_EXPIRE, date.toLocaleDateString());
		}
		setShow(false);
	};

	const closeHandler = () => {
		// const now = new Date();
		// now.setTime(now.getTime() + 30 * 24 * 60 * 60 * 1000);
		// if (typeof window !== 'undefined') {
		// 	localStorage.setItem(GDPR_CONSENT, 'true');
		// 	localStorage.setItem(GDPR_EXPIRE, now.toLocaleDateString());
		// }
		setShow(false);
	};

	return (
		<>
			{show && (
				<section className={classes['consent-container']}>
					<div className={classes['consent-info']}>
						<p>
							We use cookies to ensure that we give you the best experience on our website. If you
							continue to use this site we will assume that you are happy with it.
						</p>
						<div className={classes['actions']}>
							<ActionButton className={classes['ok-btn']} onClick={consentHandler}>
								Ok
							</ActionButton>
							<LinkButton
								className={classes['privacy-policy-btn']}
								kind="primary-color"
								href={`${origin}/privacy-policy/`}>
								Privacy policy
							</LinkButton>
						</div>
					</div>
					<IconButton className={classes['close-btn']} onClick={closeHandler}>
						<CloseIcon data-testid="close-btn" />
					</IconButton>
				</section>
			)}
		</>
	);
};

export default CookieConsent;
