import { AppRoutes } from 'router/routes';

// ** TITLE TYPE CONSTANTS
import { BLANK_TITLE } from '@core/constants/Title';
// ** Layout TYPE CONSTANTS
import { BLANK_MOBILE_LAYOUT } from '@core/constants/Layout';

export const getCurrentRouteConfig = (router) => {
	let currentRoute = AppRoutes.find((route) => route.path === router.pathname);
	if (!currentRoute) {
		currentRoute = AppRoutes.find((route) => router.pathname.startsWith(route.path));
	}
	if (currentRoute) {
		return currentRoute;
	} else {
		return {
			layout: BLANK_MOBILE_LAYOUT,
			meta: {
				title: { text: '', type: BLANK_TITLE }
			}
		};
	}
};
