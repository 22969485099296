import React from 'react';

import { useRouter } from 'next/router';

import classes from './BackButton.module.scss';

import SimpleBack from '@core/assets/icons/simple-back.svg';
import BorderedBack from '@core/assets/icons/bordered-back.svg';
import PinkBack from '@core/assets/icons/pink-back.svg';
import CloseSquare from '@core/assets/icons/close-square.svg';

// ** include these type : Simple , Bordered , text, close, cancel
// ** without type it would be Simple

const BackButton = ({ type, className, fallbackRoute }) => {
	const router = useRouter();
	const backHandler = () => {
		if (window?.history?.length > 1) {
			router.back();
		} else if (fallbackRoute) {
			router.replace(fallbackRoute);
		} else {
			router.replace('/');
		}
	};
	return (
		<button
			data-testid="back-btn"
			className={`btn d-flex flex-row p-0 ${classes['backbutton']} ${className || ''}`}
			onClick={backHandler}>
			{(!type || type === 'simple') && <SimpleBack />}
			{type === 'bordered' && <BorderedBack />}
			{type === 'text' && (
				<>
					<PinkBack />
					<span className="mb-0 ">Back</span>
				</>
			)}
			{type === 'close' && <CloseSquare />}
			{type === 'cancel' && <span>Cancel</span>}
		</button>
	);
};

export default BackButton;
