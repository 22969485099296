import React from 'react';
import { useRouter } from 'next/router';

import IconButton from '@mui/material/IconButton';

import CloseIcon from 'assets/icons/close-icon.svg';

import classes from './GoPermiumModal.module.scss';
import ActionButton from '../actionbutton/ActionButton';
import LinkButton from '../linkbutton/LinkButton';

const GoPermiumModal = ({ closeHandler }) => {
    const router = useRouter();
    return (
        <div className={classes['wrapper']}>
            <section className={classes['modal-header']}>
                <IconButton onClick={closeHandler} >
                    <CloseIcon />
                </IconButton>
            </section>
            <section className={classes['modal-body']}>
                <h3>Go Premium</h3>
                <p>Access to Job board and many other features in 2 min.</p>
                <LinkButton to={'/plans'} kind='primary-color'>
                    Go Premium
                </LinkButton>
            </section>
        </div>
    );
};

export default GoPermiumModal;