import { sendRequest } from './index';
import { API_NOTIFICATON_URL } from '@core/constants/API';

export const GetNotificationService = (payload) => {
	return sendRequest({
		url: `${API_NOTIFICATON_URL}user-notifications?page=${payload.page}`,
		method: 'GET',
		doNotRefresh: true
	});
};

export const SeenNotificationService = (id) => {
	return sendRequest({
		url: `${API_NOTIFICATON_URL}user-notifications/${id}/seen/`,
		method: 'POST',
		body: {}
	});
};

export const getNotificationSettingsService = () =>
	sendRequest({
		url: '/api/notifications/v1/settings',
		method: 'GET'
	});

export const notificationSettingsBulkUpdate = (payload) =>
	sendRequest({
		url: '/api/notifications/v1/settings/bulk-update/',
		method: 'POST',
		body: payload
	});
