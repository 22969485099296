import React from 'react';

import { Lacking, Completed, Progressing, Updated, ComingSoon } from 'constants/content-status';

import Tick from 'assets/icons/tick-blank.svg';
import InProgress from 'assets/icons/in-progress.svg';

import classes from './Badge.module.scss';

const Badge = ({ status }) => {
	if (status == Lacking) {
		return;
	}
	const getBadgeStatus = () => {
		switch (status) {
			case Completed:
				return (
					<span
						className={`d-flex align-items-center gap-1
      ${classes[status]} ${classes['badge-wrapper']}`}>
						<Tick /> Completed
					</span>
				);
			case Progressing:
				return (
					<span
						className={`d-flex align-items-center gap-1
      ${classes[status]} ${classes['badge-wrapper']}`}>
						<InProgress /> In Progress
					</span>
				);
			case Updated:
				return (
					<span
						className={`d-flex align-items-center gap-1
      ${classes[status]} ${classes['badge-wrapper']}`}>
						Updated
					</span>
				);
			case ComingSoon:
				return (
					<span
						className={`d-flex align-items-center gap-1
      ${classes[status]} ${classes['badge-wrapper']}`}>
						Coming Soon
					</span>
				);
			default:
				return;
		}
	};
	return <>{getBadgeStatus()}</>;
};

export default Badge;
