import React from 'react';
import classes from './ExploreMenu.module.scss'
import Link from 'next/link';
// ** Custom Components
import LinkButton from '@core/components/linkbutton/LinkButton';

import ArrowRightIcon from 'assets/icons/arrow-right.svg';
import JobBoardImage from 'assets/images/explore-menu-job-board.png';
import CoursesImage from 'assets/images/explore-menu-courses.png';


//this is a deprecated component as we used to show it on main menu
const ExploreMenu = () => {
    return (
        <div className={classes["explore-menu"]}>
            <div className='container'>
                <div className='row pt-5'>
                    <div className={`${classes["explore-menu-job-qualification"]} col-6 d-flex flex-row align-items-center px-5`}>
                        <img src={JobBoardImage.src} />
                        <div className={classes["explore-description"]}>
                            <div className={`${classes.title} ${classes["job-qualification"]}`}>
                                Job Board
                            </div>
                            <div className={classes.description}>
                            Explore our comprehensive job opportunities and discover exciting career prospects by visiting our Job Board.
                            </div>
                            <Link
                                href="/job-board"
                                className={classes["explore-menu-link"]}
                            >
                                Explore Job Board <ArrowRightIcon />
                            </Link>
                        </div>
                    </div>
                    <div className='col-6 d-flex flex-row align-items-center px-5'>
                        <img src={CoursesImage.src} />
                        <div className={classes["explore-description"]}>
                            <div className={`${classes.title} ${classes["courses"]}`}>
                                Courses
                            </div>
                            <div className={classes.description}>
                                Earn career credentials from industry leaders that demonstrate your expertise.
                            </div>
                            <Link
                                href="/explore?featured_contents=true&content=lecture"
                                className={classes["explore-menu-link"]}
                            >
                                Explore Courses <ArrowRightIcon />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 d-flex flex-row justify-content-center pb-4 pt-5 mt-3'>
                        {/* <LinkButton to="/explore?featured_contents=true" className={`${classes['get-started']}`}>
                            Explore All
                        </LinkButton> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExploreMenu;