import React from 'react';

import { addZeroToOneDecimal } from 'utils/numbersShowingUtils';

import classes from './PlanPrice.module.scss';

const PlanPrice = ({ period, data, isPayment = false }) => {
    return (
        <>
            {period === 'Annual' ? <div className={classes['pop-badge']}>Most Popular</div> : ''}
            <div className={classes['period']}>
                {period}
            </div>
            <div className={`${isPayment ? `${classes['is-payment']} ${classes['price']}` : classes['price']}`}>
                {period === 'Annual' ?
                    <>
                        <span className={data.yearly.discounted_price != null ? classes['discounted'] : ''}>
                            {data.yearly.discounted_price != null ?
                                <>
                                    <div className={classes['orginal-price']}>
                                        {data.yearly.currency.symbol}{addZeroToOneDecimal(data.yearly.price)}
                                    </div>
                                    <sup className={classes['symbol']}>
                                        {data.yearly.currency.symbol}
                                    </sup>
                                    {addZeroToOneDecimal(data.yearly.discounted_price)}
                                </>
                                :
                                <>
                                    <span className={classes['symbol']}>
                                        {data.yearly.currency.symbol}
                                    </span>
                                    {addZeroToOneDecimal(data.yearly.price)}
                                </>
                            }
                        </span>
                        <span className={classes['duration']}>
                            /mo
                        </span>
                    </>
                    :
                    <>
                        <span className={data.monthly.discounted_price != null ? classes['discounted'] : ''}>
                            {data.monthly.discounted_price != null ?
                                <>
                                    <div className={classes['orginal-price']}>
                                        {data.monthly.currency.symbol}{addZeroToOneDecimal(data.monthly.price)}
                                    </div>
                                    <sup className={classes['symbol']}>
                                        {data.monthly.currency.symbol}
                                    </sup>
                                    {addZeroToOneDecimal(data.monthly.discounted_price)}
                                </>
                                :
                                <>
                                    <span className={classes['symbol']}>
                                        {data.monthly.currency.symbol}
                                    </span>
                                    {addZeroToOneDecimal(data.monthly.price)}
                                </>
                            }
                        </span>
                        <span className={classes['duration']}>
                            /mo
                        </span>
                    </>}
            </div>
            <div className={`${isPayment ? `${classes['is-payment']} ${classes['save']}` : classes['save']}`}>
                {period === 'Annual' ? <>Save {data.yearly.currency.symbol}{data.yearly.saving} <span>| billed yearly</span></> : <span>billed monthly</span>}
            </div>
        </>
    );
};

export default PlanPrice;