import React from 'react';

// ** custom components
import BackButton from '@core/components/backbutton/BackButton';

import classes from './SubPageTitle.module.scss';

const BlankWithTitle = ({ title }) => {
	return (
		<div
			className={`${classes['sub-page']} container d-flex flex-row justify-content-between d-xl-none my-3`}>
			<BackButton type="simple" />
			<h1 className="px-4"> {title} </h1>
			<div></div>
		</div>
	);
};

export default BlankWithTitle;
