import React, { useEffect, useState } from 'react';

import useLocalStorage from "use-local-storage";

import classes from './SelectDarkMode.module.scss';

const SelectDarkMode = () => {
    // const [isDarkMode, setIsDarkMode] = useState('');
    const [themeName, setThemeName] = useLocalStorage("themeName", "");

    const handleChange = () => {
        if (themeName === 'dark')
            setThemeName('light');
        else
            setThemeName('dark');
    };
    useEffect(() => {


    }, []);
    return (
        <div className={classes['toggle-container']}>
            <input
                type='checkbox'
                id='darkmode'
                className={classes['toggle']}
                onChange={handleChange}
                checked={(themeName === 'dark')}
            />
            <label htmlFor='darkmode'></label>
        </div>
    );
};

export default SelectDarkMode;