import React from 'react';

import classes from './PromotionBanner.module.scss';
import LinkButton from '../linkbutton/LinkButton';


const PromotionBanner = ({ data }) => {


    const calculateTimeLeft = () => {
        const year = new Date().getFullYear();
        const difference = +new Date(data.valid_until) - +new Date();
        // const difference = +new Date("2024-07-08T13:13:18Z") - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0'),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0'),
                minutes: Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0'),
                seconds: Math.floor((difference / 1000) % 60).toString().padStart(2, '0')
            };
        }
        else
            return null;

        return timeLeft;
    }
    const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());
    React.useEffect(() => {
        const id = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => {
            clearTimeout(id);
        };
    });

    return (
        <div className={classes['wrapper']}>
            <div className="container">
                <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap text-center">
                    <p>
                        {data.description}
                        {data.valid_until && timeLeft && (
                            <span> {timeLeft.days} : {timeLeft.hours} : {timeLeft.minutes} : {timeLeft.seconds} </span>
                        )}
                    </p>
                    <LinkButton to={'/plans'}>
                        Subscribe now!
                    </LinkButton>
                </div>
            </div>
        </div>
    );
};

export default PromotionBanner;

