import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
	routesHistory: [],
	eventBus: {
		type: null,
		payload: null,
		meta: {}
	}
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		pushToHistory(state, action) {
			const stateLength = current(state.routesHistory).length;
			if (current(state.routesHistory)[stateLength - 1] !== action.payload) {
				state.routesHistory.push(action.payload);
			}
		},
		removeFromHistory(state) {
			state.routesHistory.pop();
		},
		onSubscribe: (state, newAction) => {
			state.eventBus = newAction.payload;
		}
	}
});

export const { pushToHistory, onSubscribe } = appSlice.actions;

export default appSlice.reducer;
