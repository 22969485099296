import NotificationStatus from 'assets/icons/notification-status.svg';
import classes from './EmptyList.module.scss';

const EmptyList = () => {
	return (
		<div
			className={`${classes['empty-list-container']} d-flex flex-column align-items-center justify-content-center py-4`}>
			<NotificationStatus />
			<h1>No notification yet</h1>
			<p>News and updates will appear here.</p>
		</div>
	);
};

export default EmptyList;
