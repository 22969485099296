import classes from './VIPIcon.module.scss';
import VIPCrown from 'assets/icons/vip-crown-icon.svg';
import VIPBadge from 'assets/icons/vip-badge-icon.svg';
import VIPBadgeActive from 'assets/icons/vip-badge-active-icon.svg';
import VIPCrownActive from 'assets/icons/vip-crown-active-icon.svg';

const VIPIcon = ({ active }) => {
	return (
		<div className={classes['icon']}>
			{active ? <VIPBadgeActive /> : <VIPBadge />}
			{active ? (
				<VIPCrownActive className={classes['crown']} />
			) : (
				<VIPCrown className={classes['crown']} />
			)}
		</div>
	);
};

export default VIPIcon;
