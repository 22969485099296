import { useState } from 'react';

const usePopover = () => {
	const [show, setIsShowing] = useState(false);

	const showPopover = () => {
		setIsShowing(true);
	};

	const destroyPopover = () => {
		setIsShowing(false);
	};

	return {
		show,
		showPopover,
		destroyPopover
	};
};

export default usePopover;
