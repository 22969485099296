export const ReadyForFree = 'ready_for_free';
export const Lacking = 'lacking';
export const Progressing = 'progressing';
export const Completed = 'completed';
export const Ready = 'ready';
export const Purchasing = 'purchasing';
export const Quiz = 'quiz';
export const Unsuccessful = 'unsuccessful';
export const Passed = 'passed';
export const Updated = 'updated';
export const ComingSoon = 'coming_soon';
