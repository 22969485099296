import React from 'react';

import WarningIcon from '@core/assets/icons/warning.svg';

import classes from './TextInput.module.scss';

const TextInput = ({
	validation,
	input,
	errors,
	register,
	className,
	hasErrorMsg = true,
	horizontal = false
}) => {
	return (
		<div
			className={`${horizontal ? 'row d-flex flex-row' : 'd-flex flex-column'} ${
				classes['textinput-wrapper']
			} ${className || ' '} `}>
			<label htmlFor={input.id} className={`${horizontal ? 'col-12 col-xl-4' : ''}`}>
				{input.label}
			</label>
			<input
				className={`${(errors && errors[input.name] && classes['invalid']) || ''} ${
					horizontal ? 'col-12 col-xl-8' : ''
				} ${hasErrorMsg ? '' : 'mb-0'}`}
				{...input}
				{...register(input.name, validation)}
			/>
			{hasErrorMsg && (
				<div>
					{errors[input.name] && errors[input.name].message && (
						<span className="d-flex flex-row gap-1">
							<WarningIcon /> {errors[input.name].message}
						</span>
					)}
				</div>
			)}
		</div>
	);
};

export default TextInput;
