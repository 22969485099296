import classes from './Popover.module.scss';
import CloseBtn from 'assets/icons/close-icon.svg';
import { useRef, useEffect } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

const Popover = ({ show, children, title, closeHandler, type, scrollBottomHandler }) => {
	const ref = useRef(null);	
	const listWrapperRef = useBottomScrollListener(scrollBottomHandler);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				closeHandler();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);

	useEffect(() => {
		document.body.style.overflow = show ? 'hidden' : 'auto';
		const searchHeader = document.getElementById('search-bar-header');
		searchHeader && (searchHeader.style.zIndex = show ? 0 : 999);
	}, [show]);

	if (!show) return;
	return (
		<div role="presentation" className={classes['popover-container']}>
			<div
				ref={ref}
				tabIndex={-1}
				className={`${classes['popover-content']} ${type && classes[type.toLowerCase()]}`}>
				<nav>
					<CloseBtn role="button" onClick={closeHandler} />
					<h1>{title}</h1>
				</nav>
				<main className={classes['popover-main']} ref={listWrapperRef}>{children}</main>
			</div>
		</div>
	);
};

export default Popover;
