
import React, { useEffect, useState } from 'react';

import LinkButton from '@core/components/linkbutton/LinkButton';

import Slide from '@mui/material/Slide';

import DashboardMenuDesktop from '@core/components/dashboardMenuDesktop/DashboardMenuDesktop';


import classes from './DashboardMenu.module.scss';

const DashboardMenu = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    useEffect(() => {
        if (showMobileMenu) {
            document.body.classList.add(`${classes['stop-scrolling']}`);
        } else {
            document.body.classList.remove(`${classes['stop-scrolling']}`);
        }
        return () => {
            document.body.classList.remove(`${classes['stop-scrolling']}`);
        };
    }, [showMobileMenu]);
    return (
        <>
            <div
                className={`${classes['brg-container']}`}
                data-testid="burger-menu"
                role="button"
                tabIndex="0"
                onClick={() =>
                    setShowMobileMenu((prevState) => {
                        return !prevState;
                    })
                }>
                <input className={`${classes['checkbox']}`} type="checkbox" name="" id="" />
                <div className={`${classes['hamburger-lines']}`}>
                    <span className={`${classes['line']} ${classes['line1']}`}></span>
                    <span className={`${classes['line']} ${classes['line2']}`}></span>
                    <span className={`${classes['line']} ${classes['line3']}`}></span>
                </div>
            </div>
            {showMobileMenu && (
                <div className={classes["slider-wrapper"]}></div>
            )}

            <Slide
                direction="left"
                in={showMobileMenu}
                mountOnEnter
                unmountOnExit
                timeout={{
                    enter: 500,
                    exit: 500
                }}
            >
                {

                    <div className={`${classes['menu-items']}`} data-testid="menu">
                        <div className="d-flex flex-column flex-wrap align-content-start">
                            <DashboardMenuDesktop isMobile={true} />
                        </div>
                    </div>

                }
            </Slide>
        </>
    );
};

export default DashboardMenu;