import { setCookie, getCookie, deleteCookie } from 'cookies-next';

let ssrAccessToken = undefined;
let ssrRefreshToken = undefined;
let removeTokenCookiesHandler = undefined;
let setTokenCookiesHandler = undefined;

export const getAccessToken = () => {
	return getCookie('accessToken') || ssrAccessToken;
	// return JSON.parse(localStorage.getItem('accessToken'));
};

export const getRefreshToken = () => {
	return getCookie('refreshToken') || ssrRefreshToken;
	// return JSON.parse(localStorage.getItem('refreshToken'));
};

export const setAccessToken = (accessToken) => {
	// localStorage.setItem('accessToken', JSON.stringify(accessToken));
	setCookie('accessToken', accessToken);
	ssrAccessToken = accessToken;
	setTokenCookiesHandler && setTokenCookiesHandler(accessToken);
};

export const affiliateMarketingCodeUtils = {
	get: () => localStorage.getItem('affiliate_marketing_code'),
	set: (newCode) => localStorage.setItem('affiliate_marketing_code', newCode),
	delete: () => localStorage.removeItem('affiliate_marketing_code')
};

export const setSsrTokens = (context) => {
	const { req, res } = context;
	ssrAccessToken = getCookie('accessToken', { req, res });
	ssrRefreshToken = getCookie('refreshToken', { req, res });

	removeTokenCookiesHandler = () => {
		deleteCookie('accessToken', { req, res });
		deleteCookie('refreshToken', { req, res });
	};
	setTokenCookiesHandler = (accessToken) => {
		setCookie('accessToken', accessToken, { req, res });
	};
};

export const setAuthorization = (authorization) => {
	setCookie('refreshToken', authorization.refresh);
	setCookie('accessToken', authorization.access);
	// localStorage.setItem('refreshToken', JSON.stringify(authorization.refresh));
	// localStorage.setItem('accessToken', JSON.stringify(authorization.access));
	ssrAccessToken = authorization.access;
	ssrRefreshToken = authorization.refresh;
};

export const removeAuthorization = () => {
	deleteCookie('refreshToken');
	deleteCookie('accessToken');
	removeTokenCookiesHandler && removeTokenCookiesHandler();
	// localStorage.removeItem('refreshToken');
	// localStorage.removeItem('accessToken');
	// localStorage.removeItem('userData');
	ssrAccessToken = undefined;
	ssrRefreshToken = undefined;
};

export const isUserLoggedIn = () => {
	return getCookie('accessToken') || ssrAccessToken ? true : false;
};
