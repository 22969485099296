

import classes from './Loading.module.scss';

const Loading = ({ color, size = 30 }) => {
    return (
        <div            
            className={color === 'white' ? `${classes["loading-wrapper"]} ${classes["white"]}` : `${classes["loading-wrapper"]}`}
        >
            <div style={{ width: size, height: size }} className={classes["lds-ring"]}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loading;