import React, { useEffect } from 'react';

import { useRouter } from 'next/router';
import { useStore } from 'react-redux';
import { useQuery } from 'react-query';

import { CartMetaDataService } from 'services/payments';

import IconButton from '@core/components/iconbutton/IconButton';
// import BadgeIcon from '@core/components/badgeIcon/BadgeIcon';

import ShopIcon from '@core/assets/icons/shopping-cart.svg';

import { CART_REFETCH_ACTION } from 'constants/Actions';

// import classes from './ShoppingCart.module.scss';

const ShoppingCart = () => {
	const router = useRouter();
	const { data: cartMetaData, refetch } = useQuery(['cart-meta'], () => CartMetaDataService(), {
		refetchInterval: 60000
	});
	const store = useStore();
	const clickHandler = () => {
		router.push('/cart');
	};
	useEffect(() => {
		const unsubscribe = store.subscribe(() => {
			const {
				app: { eventBus }
			} = store.getState();
			if (eventBus?.type === CART_REFETCH_ACTION) {
				refetch();
			}
		});
		return () => unsubscribe();
	}, []);

	return (
		<IconButton badge={cartMetaData?.data?.items_count} onClick={clickHandler}>
			<ShopIcon role="img" data-testid="shopping-cart-svg" />
		</IconButton>
	);
};

export default ShoppingCart;
