import { createTheme } from '@mui/material';

import palette from './palette';

export const darkTheme = createTheme({
    direction: 'ltr',
    
    components: {
        MuiTab: {
            styleOverrides: {
                root: {                    
                    color: palette.main.gray[70],
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '20px',
                    textTransform: 'capitalize',
                    borderBottom: `1px solid ${palette.main.gray[15]}`,
                    '&.Mui-selected': {
                        color: palette.main.gray[70],
                        fontWeight: '700',
                        fontSize: '18px',
                        lineHeight: '22px'
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    // borderRadius: '8px',
                    // height: '40px',
                    // padding: '8px 12px',
                    '& > .MuiFormControl-root': {
                        '& > .MuiInputBase-root': {
                            // backgroundColor: 'red',
                            borderRadius: '8px',
                            height: '40px',
                            padding: '8px 12px',
                            '& > input': {
                                position: 'absolute',
                                width: '80%'
                            }
                        },
                        '& > .MuiFormLabel-root': {
                            top: '-7px'
                        }
                    }
                },
                '&.MuiInputBase-root': {
                    borderRadius: '8px',
                    height: '40px',
                    padding: '8px 12px'
                }
            }
        }
    },
    palette: { ...palette.dark },
    typography: {
        fontFamily: '"Montserrat"',
        button: {},
        h1: {
            fontWeight: 700,
            fontSize: '3.5rem',
            lineHeight: 1.375
        },
    }
});