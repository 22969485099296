import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';



// ** custom components
import BackButton from '@core/components/backbutton/BackButton';
import ShoppingCart from 'components/common/shoppingcart/ShoppingCart';
import DashboardMenu from '../dashboardmenu/DashboardMenu';
import UserProfileMenu from '@core/components/userprofilemenu/UserProfileMenu';

import classes from './SubPageTitle.module.scss';

const SubPageTitle = ({ title }) => {

	const router = useRouter();
	return (
		<>
			<div
				className={`${classes['sub-page']} container d-flex flex-row justify-content-between d-xl-none my-3`}>
				<BackButton type="simple" />
				<h1 className="px-4"> {title} </h1>
				<div className={classes["button-wrapper"]}>
					{/* <Link
						href="/cart"
						className={`${router.pathname === '/cart' ? classes['active-cart-link'] : ''}`}>
						<ShoppingCart />
					</Link> */}
					<UserProfileMenu />
					<DashboardMenu />
				</div>
			</div>

		</>
	);
};

export default SubPageTitle;
