import React from 'react';

import classes from './periodSelector.module.scss';

const PeriodSelector = ({period, setPeriod}) => {
    return (
        <div className={period === 'Monthly' ? `${classes['tab']} ${classes['left']}` : `${classes['tab']} ${classes['right']}`}>
            <div className={period === 'Monthly' ? classes['selected'] : ''} onClick={() => setPeriod('Monthly')}>
                Monthly
            </div>
            <div className={period === 'Annual' ? classes['selected'] : ''} onClick={() => setPeriod('Annual')}>
                Annual
            </div>
        </div>
    );
};

export default PeriodSelector;