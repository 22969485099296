import { useState } from 'react';

import Cropper from 'react-easy-crop';
import { useMutation } from 'react-query';
import { userImageUpdateService } from 'services/auth';

import UseModal from 'hooks/use-modal';

import ActionButton from '@core/components/actionbutton/ActionButton';
import Modal from '@core/components/Modal/Modal';
import { MOBILE_FULLSCREEN } from '@core/constants/ModalTypes';

import { getCroppedImg, getRotatedImage } from 'utils/canvasUtils';
import { base64ToArrayBuffer, readFile } from 'utils/fileConvert';

import AvatarIcon from 'assets/icons/avatar-icon.svg';

import classes from './Avatar.module.scss';

const Avatar = ({ imageURL, refetchHandler, size }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1);
	const [imageSrc, setImageSrc] = useState(null)
	const [rotation, setRotation] = useState(0);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [yourImage, setYourImage] = useState();
	const [imageName, setImageName] = useState('');
	const { showModal, destroyModal, show } = UseModal();
	const { mutate } = useMutation(userImageUpdateService, {
		onSuccess: () => {
			refetchHandler && refetchHandler();
		}
	});

	const uploadImage = async (e) => {
		if (e.target.value.length === 0)
			return;
		let imageDataUrl = await readFile(e.target.files[0])
		setImageName(e.target.files[0].name);
		e.target.value = null;
		try {
			const orientation = await getOrientation(file)
			const rotation = ORIENTATION_TO_ANGLE[orientation]
			if (rotation) {
				imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
			}

		} catch (e) {
			console.warn('failed to detect the orientation')
		}
		setImageSrc(imageDataUrl);
		showModal();
		setYourImage(imageDataUrl);
	};
	const showCroppedImage = async () => {
		destroyModal();
		try {
			const croppedImage = await getCroppedImg(
				imageSrc,
				croppedAreaPixels,
				rotation
			)
			const formData = new FormData();
			var imageArray = base64ToArrayBuffer(croppedImage);
			formData.append('image', new File([imageArray], imageName));
			mutate(formData);

		} catch (e) {
			console.error(e)
		}
	}

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}
	return (
		<>
			<div className={(size === 'small') ? `${classes['avatar-container']} ${classes['avatar-container-small']}` : `${classes['avatar-container']}`}>
				<div className={`${classes['user-avatar']}`}>
					{imageURL ? <img src={imageURL} alt="avatar" /> :
						(
							<>
								<div className={classes["default-avatar"]}>
									<AvatarIcon />
								</div>
							</>
						)}
				</div>
				<div className={`${classes['add-image']}`}>
					<label htmlFor="user-image">{imageURL ? 'Edit Image' : 'Add Image'} </label>
					<input
						type="file"
						id="user-image"
						onChange={uploadImage}
						accept="image/*"
						className={classes['file-input']}
					/>
				</div>
			</div>
			<Modal show={show} closeHandler={destroyModal} type={MOBILE_FULLSCREEN}>
				<div className={classes["cropper-modal-container"]}>
					<div className={classes["cropper-container"]}>
						<Cropper
							image={yourImage}
							crop={crop}
							zoom={zoom}
							aspect={300 / 300}
							onCropChange={setCrop}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
						/>
					</div>
					<ActionButton
						onClick={showCroppedImage}
						className='mt-4'
					>
						Done
					</ActionButton>
				</div>
			</Modal>
		</>

	);
};

export default Avatar;
