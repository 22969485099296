import React from 'react';

import classes from './Spinner.module.scss';

const Spinner = ({ className }) => {
	return (
		<div className={`${classes['container']} ${className ? className : ''}`}>
			<svg viewBox="0 0 100 100">
				<defs>
					<filter id="shadow">
						<feDropShadow dx="0" dy="0" stdDeviation="1.5" floodColor="#fc6767" />
					</filter>
				</defs>
				<circle
					id="spinner"
					className={`${classes['spinner']}`}
					style={{
						fill: 'transparent',
						stroke: '#BB3A90',
						strokeWidth: '7px',
						strokeLinecap: 'round',
						filter: 'url(#shadow)'
					}}
					cx="50"
					cy="50"
					r="45"
				/>
			</svg>
		</div>
	);
};

export default Spinner;
