import React from 'react';

// ** TITLE TYPE CONSTANTS
import {
	MAIN_PAGE_TITLE,
	SUB_PAGE_TITLE,
	BLANK_TITLE,
	HOME_TITLE,
	HIDDEN_TITLE,
	BLANK_WITH_TITLE
} from '@core/constants/Title';

// ** TITLE TYPES COMPONENTS
import BlankTitle from './titletype/BlankTitle';
import HomePageTitle from './titletype/HomePageTitle';
import MainPageTitle from './titletype/MainPageTitle';
import SubPageTitle from './titletype/SubPageTitle';
import BlankWithTitle from './titletype/BlankWithTitle';
import PromotionBanner from '@core/components/promotionbanner/PromotionBanner';

const Title = ({ text: title, type, promotion }) => {
	if (type === HIDDEN_TITLE) {
		return;
	}
	return (
		<>
			{type === BLANK_TITLE && <BlankTitle />}
			{type === BLANK_WITH_TITLE && <BlankWithTitle title={title} />}
			{type === HOME_TITLE && <HomePageTitle />}
			{type === MAIN_PAGE_TITLE && <MainPageTitle title={title} />}
			{type === SUB_PAGE_TITLE && <SubPageTitle title={title} />}
			{promotion && <PromotionBanner data={promotion} />}
		</>
	);
};

export default React.memo(Title);
