import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

// ** custom components
import ShoppingCart from 'components/common/shoppingcart/ShoppingCart';
import Notification from 'components/notification/Notification';
import BergerMenu from '../hamburgermenu/BurgerMenu';
import UserProfileMenu from '@core/components/userprofilemenu/UserProfileMenu';
import DashboardMenu from '../dashboardmenu/DashboardMenu';

import { isUserLoggedIn } from 'utils/authentication';

import classes from './MainPageTitle.module.scss';

const MainPageTitle = ({ title }) => {
	const router = useRouter();
	return (
		<div
			className={`${classes['main-page-title']} container d-flex flex-row justify-content-between d-xl-none my-3`}>
			<div>
				<h1>{title}</h1>
			</div>
			<div className={classes['icons']}>
				{isUserLoggedIn() ? (
					<>
						<Notification />
						{/* <Link
							href="/cart"
							className={`${router.pathname === '/cart' ? classes['active-cart-link'] : ''}`}>
							<ShoppingCart />
						</Link> */}
						<UserProfileMenu />
						<DashboardMenu />
					</>
				) : (
					<BergerMenu />
				)}
			</div>
		</div>
	);
};

export default MainPageTitle;
