import { sendRequest } from './index';
import { API_DEVICES_URL } from '@core/constants/API';

export const IdentifyUserAsDevice = (payload) => {
	return sendRequest({
		url: `${API_DEVICES_URL}`,
		method: 'POST',
		body: payload
	});
};
