import { useForm } from 'react-hook-form';

import UseToast from 'hooks/use-toast';

import TextInput from '@core/components/textinput/TextInput';
import { UpdateFullnameService } from 'services/auth';
import { getErrorText } from 'utils/geterrortext';


import { useMutation } from 'react-query';

import classes from './LoggedInUser.module.scss';

const FullNameModal = ({ refetchHandler, closeModal }) => {
	const { toast, ToastContainer } = UseToast();
	const { mutate: updateFullName } = useMutation(UpdateFullnameService, {
		onSuccess: () => {
			refetchHandler();
			closeModal?.();
		},
		onError: (error) => {
			toast(getErrorText(error), 'Alert');
		},
	});

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid }
	} = useForm({ mode: 'onChange' });

	const onSubmitName = (fullnameData) => {
		updateFullName(fullnameData);
	};

	const modalHandler = (e) => {
		onSubmitName(e);
	};

	return (
		<>
			<div className={`${classes['course-certificate-verify-name']} w-100 d-flex flex-column`}>
				<h1>Add your name</h1>
				<p>Please note that your name is not editable later</p>
				<form onSubmit={handleSubmit(modalHandler)}>
					<TextInput
						validation={{
							required: true
						}}
						input={{
							type: 'text',
							label: 'First Name',
							name: 'first_name',
							placeholder: 'First Name',
							id: 'first_name'
						}}
						errors={errors}
						register={register}
					/>
					<TextInput
						validation={{
							required: true
						}}
						input={{
							type: 'text',
							label: 'Last Name',
							name: 'last_name',
							placeholder: 'Last Name',
							id: 'last_name'
						}}
						errors={errors}
						register={register}
					/>
					<label className={`${classes['verify-name-checkbox']} `} htmlFor="verify">
						<p>
							I verify that this is my full name for the use in my account and on my certificates.
						</p>
						<input
							type="checkbox"
							name="verify"
							id="verify"
							{...register('verify', {
								required: true
							})}
						/>
						<span></span>
					</label>
					<button type="submit" className="w-100" disabled={!isDirty || !isValid}>
						Submit
					</button>
				</form>
			</div>
			<ToastContainer />
		</>
	);
};

export default FullNameModal;
