import React, { useState } from 'react';

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import ActionButton from '@core/components/actionbutton/ActionButton';
import { addZeroToOneDecimal } from 'utils/numbersShowingUtils';

const CartStripeCheckout = ({ cart_id, clientSecret, returnUrl, invoice_amount, currency }) => {
	// console.log(returnUrl, '<<<<<<< RETURN URL');
	const stripe = useStripe();
	const elements = useElements();
	const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
	const [symbol] = useState(() =>
		currency !== undefined && currency.symbol !== undefined && currency.symbol !== null
			? currency.symbol
			: '€'
	);
	const [loading, setLoading] = useState(false);
	stripe &&
		clientSecret &&
		stripe.retrievePaymentIntent(clientSecret).then(function (result) {
			if (invoice_amount && invoice_amount !== "undefined" && invoice_amount !== null) 
				setTotalPriceWithTax(addZeroToOneDecimal(invoice_amount));
			else
				setTotalPriceWithTax(result?.paymentIntent?.amount / 100);
			// Handle result.error or result.paymentIntent
		});

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();
		setLoading(true);

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const return_Url = !returnUrl
			// ? `${window.location.origin}/payment-result/${cart_id}`
			? `${window.location.origin}/payment-result-subscription`
			: `${window.location.origin}${returnUrl}`;

		const result = await stripe.confirmPayment({
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				return_url: return_Url
			}
		});

		if (result.error) {
			// Show error to your customer (for example, payment details incomplete)
			setLoading(false);
			console.log(result.error.message);
		} else {
			setLoading(false);
			// console.log('well its in the else now');
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.
		}
	};
	return (
		<form onSubmit={handleSubmit} className="d-flex flex-column gap-4">
			<PaymentElement />
			<ActionButton type="submit" disabled={!stripe} loading={loading}>
				Pay {symbol}{totalPriceWithTax}
			</ActionButton>
		</form>
	);
};

export default CartStripeCheckout;
