import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';

import classes from './ActionButton.module.scss';

const ActionButton = ({ className, children, kind = 'primary', ...props }) => {
	const buttonKindHandler = () => {
		switch (kind.toLowerCase()) {
			case 'primary':
				return {
					height: '54px',
					width: '100%',
					backgroundColor: 'primary.main',
					color: 'primary.contrastText',
					fontWeight: '700',
					fontSize: '18px',
					lineHeight: '22px',
					textTransform: 'none',
					borderRadius: '12px',
					// boxShadow: '0px 12px 24px rgba(211, 86, 146, 0.2)',
					'&.Mui-disabled': {
						backgroundColor: 'gray.20',
						boxShadow: '0px 12px 24px rgba(197, 179, 187, 0.2)'
					},
					'&.MuiButtonBase-root:hover': {
						backgroundColor: 'primary.main',
						opacity: '0.9'
					}
				};
			case 'secondary':
				return {
					height: '54px',
					width: '100%',
					backgroundColor: 'primary.light',
					color: 'primary.main',
					fontWeight: '700',
					fontSize: '18px',
					lineHeight: '22px',
					textTransform: 'none',
					borderRadius: '12px',
					boxShadow: 'none',
					'&.Mui-disabled': {
						backgroundColor: 'gray.20',
						boxShadow: '0px 12px 24px rgba(197, 179, 187, 0.2)'
					},
					'&.MuiButtonBase-root:hover': {
						backgroundColor: 'primary.light',
						opacity: '0.9'
					}
				};
			case 'secondary_main':
				return {
					height: '54px',
					width: '100%',
					backgroundColor: 'secondary.main',
					color: 'primary.contrastText',
					fontWeight: '700',
					fontSize: '18px',
					lineHeight: '22px',
					textTransform: 'none',
					borderRadius: '12px',
					// boxShadow: '0px 12px 24px rgba(211, 86, 146, 0.2)',
					'&.Mui-disabled': {
						backgroundColor: 'gray.20',
						boxShadow: '0px 12px 24px rgba(197, 179, 187, 0.2)'
					},
					'&.MuiButtonBase-root:hover': {
						backgroundColor: 'secondary.main',
						opacity: '0.9'
					}
				};
			case 'secondary_main_light':
				return {
					height: '54px',
					width: '100%',
					backgroundColor: 'secondary.light',
					color: 'secondary.main',
					fontWeight: '700',
					fontSize: '18px',
					lineHeight: '22px',
					textTransform: 'none',
					borderRadius: '12px',
					'&.Mui-disabled': {
						backgroundColor: 'gray.20',
					},
					'&.MuiButtonBase-root:hover': {
						backgroundColor: 'secondary.light',
						opacity: '0.9'
					}
				};
			case 'blank':
				return {
					textTransform: 'none',
					'&.Mui-disabled': {
						backgroundColor: 'transparent'
					},
					'&.MuiButtonBase-root:hover': {
						backgroundColor: 'transparent'
					}
				};
			case 'blank-secondary':
				return {
					color: 'secondary.main',
					textTransform: 'none',
					'&.Mui-disabled': {
						backgroundColor: 'transparent'
					},
					'&.MuiButtonBase-root:hover': {
						backgroundColor: 'transparent'
					}
				};
			case 'blank-warnign':
				return {
					color: 'warning.40',
					textTransform: 'none',
					'&.Mui-disabled': {
						backgroundColor: 'transparent'
					},
					'&.MuiButtonBase-root:hover': {
						backgroundColor: 'transparent'
					}
				};
			case 'simple':
				return {
					color: 'gray.70',
					backgroundColor: 'transparent',
					textTransform: 'none',
					'&.Mui-disabled': {
						backgroundColor: 'transparent'
					},
					'&.MuiButtonBase-root:hover': {
						backgroundColor: 'transparent'
					}
				};
			case 'simple-alert':
				return {
					color: 'alert.main',
					backgroundColor: 'transparent',
					textTransform: 'none',
					'&.Mui-disabled': {
						backgroundColor: 'transparent'
					},
					'&.MuiButtonBase-root:hover': {
						backgroundColor: 'transparent'
					}
				};
			case 'alert':
				return {
					color: 'alert.main',
					backgroundColor: 'alert.light',
					textTransform: 'none',
				};
		}
	};
	const [buttonKind] = useState(buttonKindHandler());
	return (
		<LoadingButton {...props} sx={{ ...buttonKind }} className={`${className} ${classes['shrink']}`}>
			{children}
		</LoadingButton>
	);
};

export default ActionButton;
