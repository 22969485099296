export const CompanyMembershipStatus = {
	LACKING: 'lacking',
	PENDING: 'pending',
	ACTIVE: 'active'
};

export const CompanyDashboardPages = [
	{
		url: '/company/dashboard',
		name: 'dashboard',
	},
	{
		url: '/company/dashboard/job-posts-list',
		name: 'job_posts',
	},
	{
		url: '/company/dashboard/account',
		name: 'company_account',
	},
	{
		url: '/company/dashboard/user-management',
		name: 'user_management',
	},
	{
		url: '/company/dashboard/departments',
		name: 'departments',
	},
	{
		url: '/company/dashboard/payment',
		name: 'billing_information',
	},
	{
		url: '/company/dashboard/applicants-details/[id]',
		name: 'dashboard',
	},
]