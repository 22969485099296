import React, { useEffect, useRef } from 'react';

import Link from 'next/link';
import Badge from '@core/components/badge/Badge';

import UseSlugRoute from 'hooks/use-slugroute';

import StarRating from 'assets/icons/star-rating.svg';
import EmptySearch from 'assets/images/empty-search.svg';
import VIPIcon from 'assets/icons/vip-card-icon.svg';

import classes from './SearchSuggestDropdown.module.scss';

const SearchSuggestDropdown = ({ items, setShowResult, searchKeyword, forwardedRef }) => {
	const dropDownRef = useRef();
	const slugRoute = UseSlugRoute();

	const handleClickOutside = (e) => {
		if (!dropDownRef.current.contains(e.target) && !forwardedRef.current.contains(e.target)) {
			setShowResult(false);
			// setSearchKeyword('');
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div>
			{
				<div ref={dropDownRef}>
					{items.length > 0 &&
						items.map((res) => (
							<div
								className={`${classes['dropdown-wrapper']} d-flex flex-row`}
								key={res.id + res.type}>
								<img className="col-3" src={res.image} alt={res.title} />
								{res.type === 'lecture' && (
									<div className={classes['item']}>
										{/* <Badge status={res.status} /> */}
										<h4>
											<Link href={slugRoute(`/courses/${res.id}`, res.slug)}>{res.title}</Link>
											{/* {res.has_vip_access && <VIPIcon />} */}
										</h4>
										<span>
											<StarRating /> {res.rate_average}
										</span>
									</div>
								)}
								{res.type === 'course' && (
									<div className={classes['item']}>
										<h4>
											<Link href={slugRoute(`/job-qualifications/${res.id}`, res.slug)}>{res.title}</Link>
											{/* {res.has_vip_access && <VIPIcon />} */}
										</h4>
										<p className="d-flex align-items-center">
											{res.participants_count} participants <span /> {res.lecture_count} courses
										</p>
										<span>
											<StarRating /> {res.rate_average}
										</span>
									</div>
								)}
							</div>
						))}
					{items.length === 0 && (
						<div
							className={`d-flex flex-column justify-content-center align-items-center ${classes['empty-search-svg']}`}>
							<EmptySearch />
							<h4>No Result for “{searchKeyword}”</h4>
							<p>Try again with different words</p>
						</div>
					)}
				</div>
			}
		</div>
	);
};

export default SearchSuggestDropdown;
