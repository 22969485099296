import React, { useEffect, useState } from 'react';

import LinkButton from '@core/components/linkbutton/LinkButton';

import Slide from '@mui/material/Slide';
import CompanyMenu from '../menu/CompanyMenu';

import classes from './CompanyBurgerMenu.module.scss';

const BergerMenu = ({  accessible_items,  company_membership }) => {
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	useEffect(() => {
		if (showMobileMenu) {
			document.body.classList.add(`${classes['stop-scrolling']}`);
		} else {
			document.body.classList.remove(`${classes['stop-scrolling']}`);
		}
		return () => {
			document.body.classList.remove(`${classes['stop-scrolling']}`);
		};
	}, [showMobileMenu]);

	return (
		<>
			<div
				className={`${classes['brg-container']}`}
				data-testid="burger-menu"
				role="button"
				tabIndex="0"
				onClick={() =>
					setShowMobileMenu((prevState) => {
						return !prevState;
					})
				}>
				<input
					className={`${classes['checkbox']}`}
					type="checkbox"
					name=""
					id=""
					checked={showMobileMenu}
				/>
				<div className={`${classes['hamburger-lines']}`}>
					<span className={`${classes['line']} ${classes['line1']}`}></span>
					<span className={`${classes['line']} ${classes['line2']}`}></span>
					<span className={`${classes['line']} ${classes['line3']}`}></span>
				</div>
			</div>
			<Slide
				direction="right"
				in={showMobileMenu}
				mountOnEnter
				unmountOnExit
				timeout={{
					enter: 500,
					exit: 500
				}}>
				{
					<div className={`${classes['menu-items']}`}>
						<CompanyMenu							
							accessible_items={accessible_items}
							company_membership={company_membership}
						/>
					</div>
				}
			</Slide>
		</>
	);
};

export default BergerMenu;
