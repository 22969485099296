import { useRouter } from 'next/router';

import { useMutation } from 'react-query';
import { SeenNotificationService } from 'services/notification';

import NotificationIcon from 'assets/icons/vip-crown-icon-small.svg';

import classes from './NotificationList.module.scss';

const NotificationList = ({ 
	data, 
	refetchHandler,
	closePopover,
}) => {
	const router = useRouter();
	const { mutate } = useMutation(SeenNotificationService, {
		onSuccess: () => {
			refetchHandler();
		}
	});

	const timeCalc = (time) => {
		const date1 = new Date(time);
		const date2 = new Date();
		const diffTime = Math.abs(date2 - date1);
		const diffMins = Math.ceil(diffTime / (1000 * 60));
		const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
		const diffYears = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30 * 12));
		switch (true) {
			case diffMins < 60:
				return `${diffMins} min ago`;
			case diffHours < 24:
				return `${diffHours} hour ago`;
			case diffDays < 30:
				return `${diffDays} day ago`;
			case diffMonths < 12:
				return `${diffMonths} month ago`;
			default:
				return `${diffYears} year ago`;
		}
	};

	const clickHandler = (id, link) => {
		mutate(id);
		closePopover();
		let splittedLink = link.split('//').pop().split('/');
		splittedLink.shift();
		splittedLink = splittedLink.join('/');
		router.replace(`/${splittedLink}`);
	};

	const iconTypeHandler = (item) => {
		if (item.icon_type) {
			switch (item.icon_type) {
				case 'vip':
					return <NotificationIcon data-testid="notification-iconType-vip" />;
			}
		}
	};

	return (
		<div className={classes['notif-container']}>
			{data.map((item) => (
				<div
					role="button"
					tabIndex={0}
					onClick={() => clickHandler(item.id, item.link)}
					key={item.id + item.created_at}
					className={classes['notif-item']}>
					<div className={classes['sub-header-container']}>
						<h3>{item.notification_type.name}</h3>
						<span>{timeCalc(item.created_at)}</span>
					</div>
					<div className={classes['header']}>
						{!item.seen_at && <span className={classes['unseen']}></span>}
						<h1>
							{iconTypeHandler(item)}
							{item.title}
						</h1>
					</div>
					<p className={classes['desc']}>{item.description}</p>
				</div>
			))}
		</div>
	);
};

export default NotificationList;
