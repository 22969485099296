import React, { useCallback } from 'react';

import { countries } from 'country-flags-svg';

import classes from './BillingInformationData.module.scss';

const BillingInformationData = ({ billingAddress }) => {
	const getCountryName = useCallback((billingAddress) => {
		try {
			return countries.find((country) => country.iso2 === billingAddress.country_iso)['name'];
		}
		catch (e) {
			return '';
		}

	}, []);

	return (
		<ul className={`d-flex flex-column ${classes['billing-address-info']}`}>
			<li className="d-flex flex-row align-items-md-center">
				<p className="mb-2 mb-md-0">Country: </p>
				<span>{getCountryName(billingAddress)}</span>
			</li>
			<li className="d-flex flex-row align-items-md-center">
				<p className="mb-2 mb-md-0">City: </p>
				<span>{billingAddress?.city}</span>
			</li>
			<li className="d-flex flex-row align-items-md-center">
				<p className="mb-2 mb-md-0">Address Line 1: </p>
				<span>{billingAddress?.address_line_1}</span>
			</li>
			<li className="d-flex flex-row align-items-md-center">
				<p className="mb-2 mb-md-0">Address Line 2: </p>
				<span>{billingAddress?.address_line_2 ?? '-'}</span>
			</li>
			<li className="d-flex flex-row align-items-md-center">
				<p className="mb-2 mb-md-0">Postal Code: </p>
				<span>{billingAddress?.postal_code}</span>
			</li>
		</ul>
	);
};

export default BillingInformationData;
