import { lazy } from 'react';

// ** TITLE TYPE CONSTANTS
import { BLANK_TITLE, HIDDEN_TITLE } from '@core/constants/Title';
// ** Layout TYPE CONSTANTS
import { BLANK_MOBILE_LAYOUT } from '@core/constants/Layout';

const ProfileRoutes = [
	{
		path: '/get-started',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Get Started', type: HIDDEN_TITLE },
			authentication: true
		}
	},
	{
		path: '/register',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Register', type: BLANK_TITLE },
			authentication: true
		}
	},
	{
		path: '/register-verify',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Register', type: HIDDEN_TITLE },
			protected: true
		}
	},
	{
		path: '/login',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Login', type: BLANK_TITLE },
			authentication: true
		}
	},
	{
		path: '/forgot-password',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Forgot Password', type: BLANK_TITLE }
		}
	},
	{
		path: '/new-password',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Reset Password', type: BLANK_TITLE }
		}
	},
	{
		path: '/verify-email',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Verify Email', type: BLANK_TITLE }
		}
	},
	{
		path: '/verification',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Verify Email', type: BLANK_TITLE },
			protected: true
		}
	}
];

export default ProfileRoutes;
