import { useEffect, useState } from 'react';
import { getMessaging, getToken } from 'firebase/messaging';
import { firebaseApp } from '../../src/firebase';

const useFcmToken = () => {
	const [token, setToken] = useState();
	const [notificationPermissionStatus, setNotificationPermissionStatus] = useState('');

	const FetchFcmToken = async () => {
		try {
			if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
				const messaging = getMessaging(firebaseApp);

				// Retrieve the notification permission status
				const permission = await Notification.requestPermission();
				setNotificationPermissionStatus(permission);

				// Check if permission is granted before retrieving the token
				if (permission === 'granted') {
					const currentToken = await getToken(messaging, {
						vapidKey:
							'BKq7YyOXD1VHEPZiylHJ2yK6EP-FwY_PKEa3EPbLNu6a0Ows7Dyr9_gmPb5dACZP43SEvJ6NVXbkrc2IFYYquxs'
					});
					if (!!currentToken) {
						setToken(currentToken);
						localStorage.setItem('fcmToken', currentToken);
						if (currentToken !== localStorage.getItem('fcmToken')) {
							localStorage.removeItem('deviceHasBeenIdentified');
						}
					} else {
						console.log('No registration token available. Request permission to generate one.');
					}
				}
			}
		} catch (error) {
			console.log('An error occurred while retrieving token:', error);
		}
	};

	const retrieveToken = () => {
		if (token) {
			return token;
		}
		if (typeof window !== 'undefined') {
			const localStorageFcmToken = localStorage.getItem('fcmToken');
			if (localStorageFcmToken) {
				setToken(localStorageFcmToken);
				return localStorageFcmToken;
			}
		}
		return undefined;
	};

	return { fcmToken: token, notificationPermissionStatus, FetchFcmToken, retrieveToken };
};

export default useFcmToken;
