import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoggedIn: false,
	userProfile: null,
	hasSubscription: false,
	available_trial_days: 0,
};

const authSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logIn(state) {
			state.isLoggedIn = true;
		},
		logOut(state) {
			state.isLoggedIn = false;
			// remove uase data from store
			// remove token and refreshToken from localstorage
		},
		setHasSubscription(state, action){
			state.hasSubscription = action.payload;
		},
		setAvailableTrialDays(state, action){
			state.available_trial_days = action.payload;
		},
	}
});

export const { logIn, logOut, setHasSubscription, setAvailableTrialDays } = authSlice.actions;

export default authSlice.reducer;
