import { useState, useCallback } from 'react';

const UseModal = () => {
	const [show, setIsShowing] = useState(false);

	const showModal = useCallback(() => {
		setIsShowing(true);
	}, []);

	const destroyModal = useCallback(() => {
		setIsShowing(false);
	}, []);

	return {
		show,
		showModal,
		destroyModal
	};
};

export default UseModal;
