import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getAccessToken } from 'utils/authentication';
import { getErrorText } from 'utils/geterrortext';
import { useQuery, useMutation } from 'react-query';
import { getPersonalizedSubscriptionPlans } from 'services/payments';
import { PROFILE_DETAIL_ERROR, INVALID_DISCOUNT_CODE } from '@core/constants/Errors';
import SubscriptionPayment from '../SubscriptionPayment';

const SubscriptionPaymentWrapper = ({ closeHandler, toast, period, setPeriod }) => {
    const router = useRouter();
    const [discountCode, setDiscountCode] = useState('');
    const [discountErrorMsg, setDiscountErrorMsg] = useState(null);
    const [hasDiscount, setHasDiscount] = useState(false);
    const { data, isLoading, error, refetch } = useQuery(['get-subscription-plans-personalized', getAccessToken()],
        () => getPersonalizedSubscriptionPlans(discountCode), {
        onError: (e) => {
            setHasDiscount(false);
            if (e?.response?.status === +INVALID_DISCOUNT_CODE) {
                setDiscountErrorMsg(getErrorText(e));
            } else if (e.response.status === 428) {
                router.replace('/verification'); 
            }
            else {
                toast(getErrorText(e), 'Alert');
            }
        },
        onSuccess: () => {
            setDiscountErrorMsg(null);
            if (discountCode !== '')
                setHasDiscount(true);
        }
    });
    useEffect(() => {
        if(discountCode === '')
        {
            refetch();
        }            
      }, [discountCode]);
    return (
        <div className='d-flex justify-content-center'>
            {data && !isLoading && (
                <SubscriptionPayment
                    data={data?.data?.user}
                    plansData={data?.data}
                    period={period}
                    setPeriod={setPeriod}
                    closeHandler={closeHandler}
                    toast={toast}
                    discountCode={discountCode}
                    setDiscountCode={setDiscountCode}
                    refetch={refetch}
                    error={error}
                    discountErrorMsg={discountErrorMsg}
                    setDiscountErrorMsg={setDiscountErrorMsg}
                    hasDiscount={hasDiscount}
                    setHasDiscount={setHasDiscount}
                />
            )}

        </div>
    );
};

export default SubscriptionPaymentWrapper;