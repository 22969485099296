import React from 'react';

// React Router Navigate
import Link from 'next/link';

import classes from './LinkButton.module.scss';

const LinkButton = ({ className, children, to, href, kind = 'primary-light', ...props }) => {
	switch (true) {
		case to !== undefined:
			return (
				<Link
					data-testid="link-button"
					className={`d-flex justify-content-center align-items-center ${classes['link-button']} ${
						className ? className : ''
					} ${kind ? classes[kind.toLowerCase()] : ''}`}
					{...props}
					href={to ? to : '/'}>
					{children}
				</Link>
			);
		case href !== undefined:
			return (
				<a
					data-testid="link-button"
					className={`d-flex justify-content-center align-items-center ${classes['link-button']} ${
						className ? className : ''
					} ${kind ? classes[kind.toLowerCase()] : ''}`}
					{...props}
					href={href ? href : '/'}>
					{children}
				</a>
			);
		default:
			return;
	}
};

export default LinkButton;
