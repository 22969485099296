import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { affiliateMarketingCodeUtils } from 'utils/authentication';

const UseAffiliateCode = () => {
	const router = useRouter();
	const [code, setCode] = useState();

	useEffect(() => {
		if (router?.query && router?.query?.affiliate_marketing_code) {
			const { affiliate_marketing_code, ...routerQueries } = router?.query;
			if (affiliate_marketing_code) {
				setCode(affiliate_marketing_code);
				affiliateMarketingCodeUtils.set(router?.query?.affiliate_marketing_code);
				const AFMLocal = affiliateMarketingCodeUtils.get();
				if (AFMLocal) {
					router.replace({ query: routerQueries }, undefined, { shallow: true });
				}
			}
		}
	}, [router?.query?.affiliate_marketing_code]);

	useEffect(() => {
		if (code && !affiliateMarketingCodeUtils.get()) {
			affiliateMarketingCodeUtils.set(code);
		}
	}, [code]);
};

export default UseAffiliateCode;

// https://pharmuni.com?affiliate_marketing_code=first_test_code
