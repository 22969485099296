import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import UseModal from 'hooks/use-modal';
import Modal from '@core/components/Modal/Modal';
import dayjs from 'dayjs';

import { TextField } from '@mui/material';

import { MOBILE_SLIDE_UP } from '@core/constants/ModalTypes';

import { UpdateFullnameService } from 'services/auth';
import { getErrorText } from 'utils/geterrortext';
import { planOptions } from 'constants/plans.const.js';
import { SubscriptionService } from 'services/payments';
import { INVALID_DISCOUNT_CODE, BAD_REQUEST } from '@core/constants/Errors';

import TextInput from '@core/components/textinput/TextInput';
import useWindowSize from 'hooks/use-windowSize';
import EditIcon from 'assets/icons/edit-icon.svg';
import CartStripe from 'components/pages/cart/CartStripe';
import CartCountrySelect from '../cart/CartCountrySelect';
import BillingInformationData from 'components/common/BillingInformationData/BillingInformationData';
import ActionButton from '@core/components/actionbutton/ActionButton';
import PlanPrice from './plancard/PlanPrice';
import PeriodSelector from './plancard/PeriodSelector';
import Loading from '@core/components/loading/Loading';

import Bullet from 'assets/icons/secondary-bullet.svg';

import classes from './SubscriptionPayment.module.scss';

const SubscriptionPayment = ({ closeHandler,
    toast,
    data,
    plansData,
    period,
    setPeriod,
    discountCode,
    setDiscountCode,
    refetch,
    error,
    discountErrorMsg,
    setDiscountErrorMsg,
    hasDiscount,
    setHasDiscount
}) => {
    const router = useRouter();
    const [editMode, setEditMode] = useState(false);
    const today = new Date();
    const getPlanId = () => {
        return (period === 'Annual' ? 'yearly' : 'monthly')
    };
    const getDiscountCode = () => {
        return (discountCode === '' ? null : discountCode);
    }
    const {
        show: showStripe,
        destroyModal: destroyStripeModal,
        showModal: showStripeModal
    } = UseModal();
    const { mobileDevice } = useWindowSize();
    const {
        register,
        handleSubmit,
        getValues: getValuesName,
        formState: { errors, isDirty, isValid }
    } = useForm({ mode: 'onChange' });
    const { mutate: updateFullName } = useMutation(UpdateFullnameService, {
        onSuccess: () => {
            refetch();
            handl2((address) =>
                checkoutServiceCallSubscription({
                    plan_id: getPlanId(),
                    discount_code: getDiscountCode(),
                    billing_address: {
                        country_iso: selectedCountry.iso2 ? selectedCountry.iso2 : selectedCountry,
                        address_line_2: address.address_line_2?.trim() || null,
                        postal_code: address.postal_code.trim(),
                        city: address.city.trim(),
                        address_line_1: address.address_line_1.trim()
                    }
                })
            )();
        },
        onError: (error) => {
            toast(getErrorText(error), 'Alert');
        }
    });


    const {
        register: reg2,
        handleSubmit: handl2,
        setValue,
        formState: { errors: errors2, isDirty: isDirty2, isValid: isValid2 }
    } = useForm({
        mode: 'onChange',
        shouldFocusError: false,
        defaultValues: {
            country_iso: data?.country_iso,
            postal_code: data?.postal_code,
            city: data?.city,
            address_line_1: data?.address_line_1,
            address_line_2: data?.address_line_2
        }
    });
    const [selectedCountry, setSelectedCountry] = useState(data?.country_iso);
    const discountCodeChangeHandler = (e) => {
        setDiscountCode(e.target.value);
    };
    const handleValidateDiscount = () => {
        if (discountCode !== '')
            refetch();
    }
    const handleRemoveDiscount = () => {
        setDiscountCode('');
        setHasDiscount(false);
    }
    const {
        data: dataSubscription,
        mutate: checkoutServiceCallSubscription,
        isLoading: checkoutServiceCallLoadingSubscription
    } = useMutation(SubscriptionService, {
        onSuccess: (dataSubscription) => {
            if (dataSubscription?.data?.payment_intent_client_secret) {
                showStripeModal();
                setDiscountErrorMsg(null);
            }
            else
                router.replace('/payment-result-subscription?redirect_status=trial')

        },
        onError: (error) => {
            if (error?.response?.status === +INVALID_DISCOUNT_CODE) {
                setDiscountErrorMsg(getErrorText(error));
            } else if (error?.response?.status === +BAD_REQUEST && error?.response?.data?.data?.discount_code) {
                setDiscountErrorMsg(error?.response?.data?.data?.discount_code[0]);
            } else if(error?.response?.data?.data?.billing_address){
                toast('Please check your billing information.', 'Alert');
            }
            else {
                toast(getErrorText(error), 'Alert');
            }
        }
    });

    const handlePayment = () => {
        if (!data?.is_name_verified) {
            handleSubmit((nameData) =>
                updateFullName(nameData)
            )();

        } else {
            handl2((address) =>
                checkoutServiceCallSubscription({
                    plan_id: getPlanId(),
                    discount_code: getDiscountCode(),
                    billing_address: {
                        country_iso: selectedCountry?.iso2 ? selectedCountry?.iso2 : selectedCountry,
                        address_line_2: address.address_line_2?.trim() || null,
                        postal_code:  address.postal_code?.trim(),
                        city: address.city?.trim(),
                        address_line_1: address.address_line_1?.trim()
                    }
                })
            )();
        }
    }
    return (
        <>
            <div className={`${classes['wrapper']} row`}>
                <h3>Subscription</h3>
                {data && (
                    <>
                        <div className='col-xl-7 col-12'>
                            {plansData.available_trial_days > 0 && (
                                <div className={classes['trial-wrapper']}>
                                    <div className='d-none d-sm-block'>
                                        <div className={classes['date-wrapper']}>
                                            <span>Today</span>
                                            <span>{dayjs().add(plansData.available_trial_days, 'day').format('DD MMM YY')}</span>
                                        </div>
                                        <div className={classes['item-wrapper']}>
                                            <div className={classes['item']}><Bullet />Trial starts</div>
                                            <div className={classes['border']}></div>
                                            <div className={classes['item']}><Bullet />Trial ends</div>
                                        </div>
                                        <div className="d-flex align-items-start justify-content-between mt-1">
                                            <p className={classes['info']}>
                                                Full access to all premium pharmuni features.
                                            </p>
                                            <p className={classes['info']}>
                                                If you added payment method, payment will be made automatically for the premium plan.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='d-block d-sm-none'>
                                        <div className={classes['trial-wrapper-mobile']}>
                                            <span>Today</span>
                                            <div className={classes['item']}><Bullet />Trial starts</div>
                                            <p className={classes['info']}>
                                                Full access to all premium pharmuni features.
                                            </p>
                                            <span>{dayjs().add(plansData.available_trial_days, 'day').format('DD MMM YY')}</span>
                                            <div className={classes['item']}><Bullet />Trial ends</div>
                                            <p className={classes['info']}>
                                                If you added payment method, payment will be made automatically for the premium plan.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!data?.is_name_verified && (
                                <div className={classes['name-wrapper']}>
                                    <form>
                                        <TextInput
                                            validation={{
                                                required: true
                                            }}
                                            input={{
                                                type: 'text',
                                                label: 'First Name',
                                                name: 'first_name',
                                                placeholder: 'First Name',
                                                id: 'first_name'
                                            }}
                                            errors={errors}
                                            register={register}
                                        />
                                        <TextInput
                                            validation={{
                                                required: true
                                            }}
                                            input={{
                                                type: 'text',
                                                label: 'Last Name',
                                                name: 'last_name',
                                                placeholder: 'Last Name',
                                                id: 'last_name'
                                            }}
                                            errors={errors}
                                            register={register}
                                        />
                                        <label className={`${classes['verify-name-checkbox']} `} htmlFor="verify">
                                            <p>
                                                I verify that this is my full name for the use in my account and on my certificates.
                                            </p>
                                            <input
                                                type="checkbox"
                                                name="verify"
                                                id="verify"
                                                {...register('verify', {
                                                    required: true
                                                })}
                                            />
                                            <span></span>
                                        </label>
                                    </form>
                                </div>
                            )}
                            <div className={classes['address-wrapper']}>
                                <h4>Add billing information</h4>
                                {((data.country_iso || data.city || data.postal_code || data.address_line_1) && !editMode) ? (
                                    <>
                                        <div
                                            className={`d-flex flex-row align-items-center justify-content-end mb-3 ${classes['update-billing-btn']}`}>
                                            <ActionButton kind='secondary' onClick={() => setEditMode(true)}>
                                                <EditIcon />
                                            </ActionButton>
                                        </div>
                                        <BillingInformationData billingAddress={data} />
                                    </>

                                ) : (
                                    <form
                                        className={`row ${classes['billing-address-form']}`}
                                    >
                                        <CartCountrySelect
                                            validation={{
                                                required: true
                                            }}
                                            input={{
                                                type: 'text',
                                                label: 'Country',
                                                name: 'country',
                                                placeholder: '',
                                                id: 'country',
                                                autoComplete: 'off'
                                            }}
                                            errors={errors2}
                                            register={reg2}
                                            selectedCountry={selectedCountry}
                                            setSelectedCountry={setSelectedCountry}
                                            mobileDevice={mobileDevice}
                                            setValue={setValue}
                                            className={`col-12 col-md-6`}
                                        />
                                        <TextInput
                                            validation={{
                                                required: true,
                                                validate: value => value.trim() !== ''
                                            }}
                                            input={{
                                                type: 'text',
                                                label: 'City',
                                                name: 'city',
                                                placeholder: 'City',
                                                id: 'city'
                                            }}
                                            errors={errors2}
                                            register={reg2}
                                            hasErrorMsg={false}
                                            className={`col-12 col-md-6`}
                                        />
                                        <TextInput
                                            validation={{
                                                required: true,
                                                validate: value => value.trim() !== ''
                                            }}
                                            input={{
                                                type: 'text',
                                                label: 'Address Line 1',
                                                name: 'address_line_1',
                                                placeholder: 'Address Line 1',
                                                id: 'address_line_1'
                                            }}
                                            errors={errors2}
                                            register={reg2}
                                            hasErrorMsg={false}
                                            className={`col-12`}
                                        />
                                        <TextInput
                                            input={{
                                                type: 'text',
                                                label: 'Address Line 2',
                                                name: 'address_line_2',
                                                placeholder: 'Address Line 2',
                                                id: 'address_line_2'
                                            }}
                                            errors={errors2}
                                            register={reg2}
                                            hasErrorMsg={false}
                                            className={`col-12`}
                                        />
                                        <TextInput
                                            validation={{
                                                required: true,
                                                validate: value => value.trim() !== ''
                                            }}
                                            input={{
                                                type: 'text',
                                                label: 'Postal Code',
                                                name: 'postal_code',
                                                placeholder: 'Postal Code',
                                                id: 'postal_code'
                                            }}
                                            errors={errors2}
                                            register={reg2}
                                            hasErrorMsg={false}
                                            className={`col-12`}
                                        />
                                    </form>
                                )}
                            </div>

                            <div
                                className={`${classes['discount']} d-flex flex-column`}>
                                <h4>Discount Code</h4>
                                <div>
                                    <div className="d-flex flex-row align-items-center gap-2">
                                        <TextField
                                            type="text"
                                            autoComplete="discount-code"
                                            id="discount-code"
                                            name="discount-code"
                                            placeholder="Enter your code here"
                                            variant="outlined"
                                            sx={{ '& > .MuiInputBase-root': { borderRadius: '8px', backgroundColor: `${hasDiscount ? '#E6F0DA' : discountErrorMsg ? '#FFE5E5' : '#fff'}` } }}
                                            onChange={discountCodeChangeHandler}
                                            value={discountCode}
                                            disabled={hasDiscount}
                                            error={error}
                                        />
                                        <ActionButton
                                            kind="blank-secondary"
                                            disabled={(discountErrorMsg === null && plansData?.monthly?.discount_message && hasDiscount)}
                                            onClick={handleValidateDiscount}>
                                            apply
                                        </ActionButton>
                                    </div>
                                    {(discountErrorMsg === null && hasDiscount) ? (
                                        <p className={`${classes['discount-msg']}`}>
                                            {period === 'Monthly' && plansData?.monthly?.discount_message && (
                                                plansData?.monthly?.discount_message
                                            )}
                                            {period === 'Annual' && plansData?.yearly?.discount_message && (
                                                plansData?.yearly?.discount_message
                                            )}
                                        </p>
                                    ) : (
                                        <div className={classes['discount-error-message']}>
                                            {discountErrorMsg}
                                        </div>
                                    )}
                                    {(discountErrorMsg === null && plansData?.monthly?.discount_message && hasDiscount) &&
                                        <div className={classes['remove-button-wrapper']}>
                                            <ActionButton
                                                kind="blank-secondary"
                                                onClick={handleRemoveDiscount}>
                                                Remove discount
                                            </ActionButton>
                                        </div>
                                    }

                                </div>
                            </div>
                            <div className='d-block d-xl-none'>
                                <ActionButton kind='secondary_main'
                                    onClick={handlePayment}
                                    disabled={data?.is_name_verified ? false : (!isDirty || !isValid)}
                                >
                                    {checkoutServiceCallLoadingSubscription ? (
                                        <Loading color={'white'} />
                                    ) : (
                                        plansData.available_trial_days > 0 ? `Start your ${plansData.available_trial_days} days trial` : 'Save and proceed to payment'
                                    )}
                                </ActionButton>
                            </div>
                        </div>
                        <div className='col-xl-5 d-none d-xl-flex flex-column align-items-center justify-content-center'>
                            <article className={`${classes['plan-card']}`}>
                                <PlanPrice period={period} data={plansData} isPayment={true} />
                                <PeriodSelector period={period} setPeriod={setPeriod} />
                                <ul>
                                    {planOptions.premium.map((option) =>
                                        <li>{option.description}</li>
                                    )}
                                </ul>
                            </article>
                            <div className={classes['action-wrapper']}>
                                <ActionButton kind='secondary_main'
                                    onClick={handlePayment}
                                    disabled={data?.is_name_verified ? false : (!isDirty || !isValid)}
                                >
                                    {checkoutServiceCallLoadingSubscription ? (
                                        <Loading color={'white'} />
                                    ) : (
                                        plansData.available_trial_days > 0 ? `Start your ${plansData.available_trial_days} days trial` : 'Save and proceed to payment'
                                    )}
                                </ActionButton>
                            </div>
                        </div>
                    </>
                )}
            </div >
            <Modal show={showStripe} type={MOBILE_SLIDE_UP} closeHandler={destroyStripeModal}>
                <div className={`${classes['stripe-wrapper-modal']} d-flex flex-column gap-2`}>
                    <CartStripe
                        clientSecret={dataSubscription?.data?.payment_intent_client_secret}
                        invoice_amount={dataSubscription?.data?.invoice_amount}
                        currency={dataSubscription?.data?.currency}
                    />
                </div>
            </Modal>
        </>
    );
};

export default SubscriptionPayment;