import React from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { isUserLoggedIn } from 'utils/authentication';
// ** custom components
import ShoppingCart from 'components/common/shoppingcart/ShoppingCart';
import Notification from 'components/notification/Notification';

import SearchSuggest from 'components/common/search/searchsuggest/SerachSuggest';
import BergerMenu from '../hamburgermenu/BurgerMenu';
import UserProfileMenu from '@core/components/userprofilemenu/UserProfileMenu';

import PharmUniLogo from '@core/assets/logo/pharmuni-main.svg';
import PharmuniBusinessMobile from '@core/assets/icons/pharmuni-business-mobile.svg';

import classes from './HomePageTitle.module.scss';

const HomePageTitle = () => {
	const router = useRouter();
	return (
		<div className="container mb-2">
			<div
				className={`${classes[isUserLoggedIn() ? 'home-page-title-loggedin' : 'home-page-title-guest']
					} d-flex flex-row justify-content-between d-xl-none my-3 align-items-center`}>
				{isUserLoggedIn() ? (
					<div>
						{isUserLoggedIn() && <span>Welcome back! 👋</span>}
						<h1>Pharmuni</h1>
					</div>
				) : (
					<PharmUniLogo />
				)}
				<div className={classes['icons']}>
					{isUserLoggedIn() ? (
						<>
							{/* <Link
								href="/company"
							>
								<PharmuniBusinessMobile />
							</Link> */}
							<Notification />
							{/* <Link
								href="/cart"
								className={`${router.pathname === '/cart' ? classes['active-cart-link'] : ''}`}>
								<ShoppingCart />
							</Link> */}
							<UserProfileMenu />

						</>
					) : (
						<BergerMenu />
					)}
				</div>
			</div>
			<SearchSuggest type="mobile" className='pe-3' />
		</div>
	);
};

export default HomePageTitle;
