import { useMutation } from 'react-query';
import { useRouter } from 'next/router';

import useDeviceIdentify from 'hooks/use-deviceidentify';

import { logoutService } from 'services/auth';
import { removeAuthorization, getRefreshToken } from 'utils/authentication';

import classes from './Logout.module.scss';

const LogoutModal = ({ closeModal }) => {
	const router = useRouter();
	
	const { handleDeviceIdentification } = useDeviceIdentify();
	
	const { mutate } = useMutation(logoutService, {
		onSuccess: () => {
			removeAuthorization();
			handleDeviceIdentification();
			router.replace('/login');
		}
	});
	const logoutHandler = () => {
		const refresh = getRefreshToken();
		mutate({
			refresh
		});
	};
	return (
		<div className={`${classes['logout-modal']} container`}>
			<h1>Log out</h1>
			<p>Are you sure you want to log out of your account?</p>
			<div className={classes['modal-actions']}>
				<button onClick={closeModal}>Cancel</button>
				<button className={classes['logout-button']} onClick={logoutHandler}>
					Log out
				</button>
			</div>
		</div>
	);
};

export default LogoutModal;
