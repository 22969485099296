import React, { useEffect } from 'react';

import LinkButton from '@core/components/linkbutton/LinkButton';
import { isUserLoggedIn } from 'utils/authentication';

import Slide from '@mui/material/Slide';

import classes from './BurgerMenu.module.scss';
import { useState } from 'react';

const BergerMenu = () => {
	const isAuthenticated = isUserLoggedIn();
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	useEffect(() => {
		if (showMobileMenu) {
			document.body.classList.add(`${classes['stop-scrolling']}`);
		} else {
			document.body.classList.remove(`${classes['stop-scrolling']}`);
		}
		return () => {
			document.body.classList.remove(`${classes['stop-scrolling']}`);
		};
	}, [showMobileMenu]);
	return (
		<>
			<div
				className={`${classes['brg-container']}`}
				data-testid="burger-menu"
				role="button"
				tabIndex="0"
				onClick={() =>
					setShowMobileMenu((prevState) => {
						return !prevState;
					})
				}>
				<input className={`${classes['checkbox']}`} type="checkbox" name="" id="" />
				<div className={`${classes['hamburger-lines']}`}>
					<span className={`${classes['line']} ${classes['line1']}`}></span>
					<span className={`${classes['line']} ${classes['line2']}`}></span>
					<span className={`${classes['line']} ${classes['line3']}`}></span>
				</div>
			</div>
			<Slide
				direction="right"
				in={showMobileMenu}
				mountOnEnter
				unmountOnExit
				timeout={{
					enter: 500,
					exit: 500
				}}>
				{
					<div className={`${classes['menu-items']}`} data-testid="menu">
						<div className="d-flex flex-column flex-wrap align-content-start">
							<ul className="px-0">
								<li>
									<a href={`/explore/`} rel="noreferrer">
										Explore
									</a>
								</li>
								<li>
									<a href={`/job-board/`} rel="noreferrer">
										Job Board
									</a>
								</li>
								<li>
									<a href={`${window?.location.origin}/our-impact/`} rel="noreferrer">
										Our Impact
									</a>
								</li>
								<li>
									<a href={`${window?.location.origin}/career-hub/`} rel="noreferrer">
										Career Hub
									</a>
								</li>
								<li>
									<a href={`${window?.location.origin}/blog/`} rel="noreferrer">
										Blog
									</a>
								</li>
								<li>
									<a href={`${window?.location.origin}/our-mission/`} rel="noreferrer">
										Our mission
									</a>
								</li>
								<li>
									{isAuthenticated ? (										
										<a href="/contact-us">Contact Us</a>
									) : (
										<a href="/login?next=/contact-us">Contact Us</a>
									)}
								</li>


							</ul>
							<LinkButton
								className={`${classes['login-register-btn']} px-3 py-3`}
								to="/get-started">
								Login / Register
							</LinkButton>
							<ul className="px-0">
								<li>
									<a href={`${window?.location.origin}/terms-and-conditions/`} rel="noreferrer">
										Terms and Conditions
									</a>
								</li>
								<li>
									<a href={`${window?.location.origin}/privacy-policy/`} rel="noreferrer">
										Privacy Policy
									</a>
								</li>
								<li>
									<a href={`${window?.location.origin}/faq/`} rel="noreferrer">
										FAQ
									</a>
								</li>
							</ul>
						</div>
					</div>
				}
			</Slide>
		</>
	);
};

export default BergerMenu;
