import React from 'react';

import CompanyMenu from 'components/pages/company/dashboard/menu/CompanyMenu';

import classes from './CompanyDashboard.module.scss';

const CompanyDashboard = ({ children, data }) => {
    return (
        <div className={`${classes['content-wrapper']} d-flex align-items-center justify-content-center`}>
            <div className="d-flex flex-column w-100 align-self-start">
                {data.company_membership === 'active' && (
                    <div className={`${classes['company-dashboard-header']} py-4`}>
                        <div className={`container d-flex flex-row align-items-center justify-content-between`}>
                            <div
                                className={`${classes['company-dashboard-header_company-name']} d-flex flex-row align-items-center gap-4`}>
                                {data.company_logo && <img src={data.company_logo} alt="company-logo" />}{' '}
                                {data.company_name && <h5>{data.company_name}</h5>}
                            </div>
                            <h5 className={`${classes['company-dashboard-header_company-role']}`}>
                                {data.role_name ? `You are ${data.role_name}!` : ''}
                            </h5>
                        </div>
                    </div>
                )}

                <div className={`container`}>                   
                    <div className="row mb-5 mt-3">
                        <div className="d-none d-xl-block col-3">
                            <CompanyMenu
                                accessible_items={data.accessible_items}
                                company_membership={data.company_membership}
                            />
                        </div>
                        <div className="col-12 col-xl-9">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyDashboard;