import React from 'react';

// ** custom components
import BackButton from '@core/components/backbutton/BackButton';

// only displays in mobile
const BlankTitle = () => {
	return (
		<div className={`container d-flex flex-row d-md-none my-3`}>
			<BackButton type="simple" />
		</div>
	);
};

export default BlankTitle;
