import React from 'react';

import { useRouter } from 'next/router';
import Link from 'next/link';

import UserProfileMenu from '@core/components/userprofilemenu/UserProfileMenu';

// ** custom components
import Notification from 'components/notification/Notification';

import classes from './DesktopMenuLoggedIn.module.scss';

import Logo from '@core/assets/logo/pharmuni-business.svg';
import CompanyIcon from 'assets/icons/home-hashtag.svg'
import JobBoardIcon from 'assets/icons/briefcase-icon.svg'
import ProfileIcon from '@core/assets/icons/profile.svg';

const DesktopMenuCompany = () => {
	const router = useRouter();
	return (
		<nav className={`${classes['nav-menu']} d-none d-xl-flex align-items-center`}>
			<div className="container d-flex justify-content-between  align-items-center">
				<Link href="/company/dashboard" replace>
					<Logo data-testid="logo" />
				</Link>
				<ul
					className={`${classes['nav-links-loggedin']} d-flex justify-content-between m-0  gap-4`}>
					<Link
						href="/company/dashboard"
						className={`${
							router.pathname === '/company/dashboard' ? classes['active-link'] : ''
						}`}>
						<CompanyIcon />
						My Company
					</Link>
					<Link
						href="/company/job-board"
						className={`${router.pathname === '/company/job-board' ? classes['active-link'] : ''}`}>
						<JobBoardIcon />
						<span>Job Board</span>
					</Link>
					{/* <Link
						href="#"
						className={`${router.pathname === '/wishlist' ? classes['active-link'] : ''}`}>
						<WishlistIcon />
						<span>Companies</span>
					</Link> */}
				</ul>
				<div className="d-flex justify-content-between gap-3">
					{/* <Notification /> */}
					{/* <Link
						href="/profile"
						className={`${router.pathname === '/profile' ? classes['active-cart-link'] : ''}`}>
						<ProfileIcon />
					</Link> */}
					<UserProfileMenu isBusiness={true} />
				</div>
			</div>
		</nav>
	);
};

export default DesktopMenuCompany;
