import { IdentifyUserAsDevice } from 'services/device';
import { useMutation } from 'react-query';

import useFcmToken from './use-fcmtoken';

const useDeviceIdentify = () => {
	const { mutate } = useMutation(IdentifyUserAsDevice, {
		onSuccess: () => {
			localStorage.setItem('deviceHasBeenIdentified', true);
		},
		onError: () => {
			localStorage.removeItem('deviceHasBeenIdentified');
		}
	});

	const { retrieveToken } = useFcmToken();

	const getDeviceId = async () => {
		const deviceId = localStorage.getItem('deviceId');
		if (deviceId) {
			return deviceId;
		} else {
			if (typeof navigator !== 'undefined' && navigator) {
				const { DeviceUUID } = require('device-uuid');
				const uuid = new DeviceUUID().get();
				if (uuid) {
					localStorage.setItem('deviceId', uuid);
					return uuid;
				}
			}
		}
	};

	const handleDeviceIdentification = async () => {
		const registration_id = retrieveToken();
		const device_id = await getDeviceId();
		if (registration_id && device_id) {
			const payload = {
				registration_id,
				device_id,
				type: 'web'
			};
			// console.log(payload, 'device Identification payload');
			mutate(payload);
		}
	};

	return {
		handleDeviceIdentification
	};
};

export default useDeviceIdentify;
