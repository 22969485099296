import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported as analyticIsSupported } from 'firebase/analytics';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

export const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export { app as firebaseApp };

let messaging = undefined;

messaging = (async () => {
	if (typeof window !== 'undefined') {
		const isSupportedAnalytic = await analyticIsSupported();
		if (isSupportedAnalytic) {
			getAnalytics(app);
		}

		const isSupportedMessaging = await isSupported();
		if (isSupportedMessaging) {
			return getMessaging(app);
		} else {
			return null;
		}
	}
})();
// if (typeof window !== 'undefined') {
// 	const isSupportedAnalytic = await analyticIsSupported();
// 	if (isSupportedAnalytic) {
// 		getAnalytics(app);
// 	}

// 	const isSupportedMessaging = await isSupported();
// 	if (isSupportedMessaging) {
// 		messaging = getMessaging(app);
// 	}
// }

export const getMessagingToken = async () => {
	const isSupportedMessaging = await isSupported();
	if (isSupportedMessaging) {
		try {
			const token = await getToken(messaging, {
				vapidKey:
					'BKq7YyOXD1VHEPZiylHJ2yK6EP-FwY_PKEa3EPbLNu6a0Ows7Dyr9_gmPb5dACZP43SEvJ6NVXbkrc2IFYYquxs'
			});
			if (token) {
				localStorage.setItem('clientId', JSON.stringify(token));
				return token;
			}
		} catch (err) {
			console.log(`An error occurred while retrieving token. , ${err}`);
		}
	}
	// if (!isSupportedMessaging()) return;
};

export const onMessageListener = async () => {
	const isSupportedMessaging = await isSupported();
	const messagingResolve = await messaging;
	if (isSupportedMessaging) {
		return new Promise((resolve) => {
			onMessage(messagingResolve, (payload) => {
				resolve(payload);
			});
		});
	}
};
