// ** Routes Imports
import PagesRoutes from './Pages';
import AuthRoutes from './Auth';
import ProfileRoutes from './Profile';
import CompanyRoutes from './Company';
import CoverLetterRoutes from './CoverLetter';

// ** Merge Routes
const AppRoutes = [...PagesRoutes, ...ProfileRoutes, ...AuthRoutes, ...CompanyRoutes, ...CoverLetterRoutes];

export { AppRoutes };
