import React from 'react';
// import  from '@src/assets/images/logo/logo.png'
import LogoLoader from '@core/assets/loading-logo.svg';
import Spinner from '@core/components/spinner/Spinner';

import classes from './Fallback-spinner.module.scss';

const FallBackSpinner = () => {
	return (
		<div className={`${classes['fallback-spinner']}`}>
			<LogoLoader data-testid="loading-logo.svg" />
			<Spinner className="mt-3" />
		</div>
	);
};

export default FallBackSpinner;
