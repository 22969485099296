import axios from 'axios';

import { API_BASE_URL, API_SSR_BASE_URL } from '@core/constants/API';
import {
	getRefreshToken,
	getAccessToken,
	removeAuthorization,
	setAccessToken
} from 'utils/authentication';

function refreshToken() {
	return Axios.post(
		'api/auth/v1/token/refresh',
		{
			refresh: getRefreshToken()
		},
		{ isRefresh: true }
	);
}

const Axios = axios.create({
	baseURL: `${API_BASE_URL}`,
	headers: {
		'Content-Type': 'application/json'
	}
});

Axios.interceptors.request.use(
	(config) => {
		// TODO
		// const token = getLocalAccessToken();
		// // we can check Expiration time here and get new token first
		if (config.headers['x-forwarded-for'] !== undefined)
			config.baseURL = API_SSR_BASE_URL;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

Axios.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const OriginalConfig = err.config;
		if (err.response) {
			// Access Token was Expired
			if (
				err.response.status === 401 &&
				!OriginalConfig._retry &&
				!err?.config?.isRefresh &&
				!err?.config?.doNotRefresh &&
				getRefreshToken()
			) {
				OriginalConfig._retry = true;
				try {
					const newAccessToken = await refreshToken();
					const { access: accessToken } = newAccessToken.data;
					setAccessToken(accessToken);
					Axios.defaults.headers.common['Authorization'] = accessToken;
					OriginalConfig.headers['Authorization'] = `Bearer ${accessToken}`;
					return Axios(OriginalConfig);
				} catch (_error) {
					if (_error.response.status === 401 && _error?.config?.isRefresh) {
						removeAuthorization();
						delete OriginalConfig.headers['Authorization'];
						delete Axios.defaults.headers.common['Authorization'];
						if (typeof window !== "undefined") {
							window?.location.reload();
						}
						return Axios(OriginalConfig);
					}
					if (_error.response && _error.response.data) {
						removeAuthorization();
						return Promise.reject(_error.response.data);
					}
					return Promise.reject(_error);
				}
			} else if (err.response.status === 401 && !getRefreshToken() && getAccessToken()) {
				removeAuthorization();
				delete OriginalConfig.headers['Authorization'];
				delete Axios.defaults.headers.common['Authorization'];
				if (typeof window !== "undefined") {
					window?.location.reload();
				}
			} else if (err.response.status === 435) {
				if (typeof window !== "undefined") {
					window.location.href = '/user-dashboard';
				}
			} else if (err.response.status === 436) {
				if (typeof window !== "undefined") {
					window.location.href = '/plans';
				}
			}
			else {
				return Promise.reject(err);
			}
		}
		return Promise.reject(err);
	}
);

export default Axios;
