import React, { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
//** Material UI
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
// ** Custom Components
import LinkButton from '@core/components/linkbutton/LinkButton';
import SearchSuggest from 'components/common/search/searchsuggest/SerachSuggest';
import ExploreMenu from 'components/common/ExploreMenu/ExploreMenu';

import classes from './DesktopMenuGuest.module.scss';

import Logo from '@core/assets/logo/pharmuni-green.svg';
import ArrowDownIcon from 'assets/icons/arrow-down-gray.svg';
import SelectDarkMode from '@core/components/selectdarkmode/SelectDarkMode';


const NavigationMenuGuest = () => {
	const router = useRouter();
	const [openAbout, setOpenAbout] = useState(false);
	const [openExplore, setOpenExplore] = useState(false);
	const anchorRefAbout = useRef(null);
	const anchorRefExplore = useRef(null);
	const handleToggleAbout = () => {
		setOpenAbout((prevOpen) => !prevOpen);
	};
	const handleCloseAbout = () => {
		setOpenAbout(false);
	};
	const handleToggleExplore = () => {
		setOpenExplore((prevOpen) => !prevOpen);
	};
	const handleCloseExplore = () => {
		setOpenExplore(false);
	};


	return (
		<>
			<div className={`${classes['nav-main-menu']} d-none d-xl-flex align-items-center`}>
				<div className="container d-flex justify-content-start  align-items-center">
					<Link
						href="/"
						className={classes['active-link']}>
						For <span>Individuals</span>
					</Link>
					<Link
						href="https://pharmuni.com/business/">
						For Businesses 
					</Link>
					<Link
						href="https://pharmuni.com/universities/">
						For Universities
					</Link>
				</div>
			</div>
			<nav className={`${classes['nav-menu']} d-none d-xl-flex align-items-center`}>

				<div className="container d-flex justify-content-between  align-items-center">
					<Link href="/" replace>
						<Logo role="img" />
					</Link>
					<SearchSuggest type="desktop" />
					<ul className={`${classes['nav-links-guest']} d-flex justify-content-between m-0 gap-4`}>
						<Link
							href="/explore?featured_contents=true"
							className={`${router.pathname === '/explore' ? classes['active-link'] : ''}`}>
							Explore
						</Link>
						{/* <Button
							ref={anchorRefExplore}
							id="explore-button"
							aria-controls={openExplore ? 'explore-menu' : undefined}
							aria-expanded={openExplore ? 'true' : undefined}
							aria-haspopup="true"
							onClick={handleToggleExplore}
						>
							Explore
						</Button>
						<Popper
							className={classes["explore-menu"]}
							open={openExplore}
							anchorEl={anchorRefExplore.current}
							role={undefined}
							placement="bottom"
							transition
							disablePortal
						>
							{({ TransitionProps, placement }) => (
								<Grow
									{...TransitionProps}
									style={{
										transformOrigin: 'top',
									}}
								>
									<Paper>
										<ClickAwayListener onClickAway={handleCloseExplore}>
											<span>
												<ExploreMenu />
											</span>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper> */}


						{/* <Link
							href="/job-board"
							className={`${router.pathname === '/job-board' ? classes['active-link'] : ''}`}>
							Job Board
						</Link> */}
						<Link
							href="/plans"
							className={`${router.pathname === '/plans' ? classes['active-link'] : ''}`}>
							Pharmuni Premium
						</Link>
						<Button
							ref={anchorRefAbout}
							id="composition-button"
							aria-controls={openAbout ? 'composition-menu' : undefined}
							aria-expanded={openAbout ? 'true' : undefined}
							aria-haspopup="true"
							onClick={handleToggleAbout}
						>
							About Us
							<ArrowDownIcon />
						</Button>
						<Popper
							className={classes["about-menu"]}
							open={openAbout}
							anchorEl={anchorRefAbout.current}
							role={undefined}
							placement="bottom"
							transition
							disablePortal
						>
							{({ TransitionProps, placement }) => (
								<Grow
									{...TransitionProps}
									style={{
										transformOrigin: 'top',
									}}
								>
									<Paper>
										<ClickAwayListener onClickAway={handleCloseAbout}>
											<MenuList
												autoFocusItem={openAbout}
												id="composition-menu"
												aria-labelledby="composition-button"
											>
												<MenuItem onClick={() => window.location.href = `${window?.location.origin}/our-impact/`}>
													Our Impact
												</MenuItem>
												<MenuItem onClick={() => window.location.href = `${window?.location.origin}/career-hub/`}>
													Career Hub
												</MenuItem>
												<MenuItem onClick={() => window.location.href = `${window?.location.origin}/our-mission/`}>
													Our Mission
												</MenuItem>
												<MenuItem onClick={() => window.location.href = `${window?.location.origin}/blog/`}>
													Blog
												</MenuItem>
											</MenuList>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>

					</ul>
					<div className="d-flex flex-row gap-2">
						<div style={{ borderRight: '1px solid #CCCBC9' }}>
							{/* <LinkButton
								kind="secondary-simple"
								href="/company"
								target="_blank"
								rel="noreferrer"
								className={`${classes['get-started']}`}>
								Pharmuni Business
							</LinkButton> */}
						</div>
						<LinkButton kind="simple" to="/login" className={`${classes['get-started']} ${classes['login']}`}>
							Login
						</LinkButton>
						<LinkButton to="/get-started" className={`${classes['get-started']}`}>
							Join for Free
						</LinkButton>
						{/* <SelectDarkMode /> */}
					</div>
				</div>
			</nav>
		</>
	);
};

export default NavigationMenuGuest;
