import { sendRequest } from './index';
import { API_AUTH_URL } from '@core/constants/API';

export const registerService = (payload) =>
	sendRequest({
		url: `${API_AUTH_URL}register`,
		method: 'POST',
		body: payload
	});

export const generateVerificationEmailService = () =>
	sendRequest({
		url: `${API_AUTH_URL}email-verification/generate`,
		method: 'POST'
	});

export const confirmVerificationEmailService = (payload) =>
	sendRequest({
		url: `${API_AUTH_URL}email-verification/confirm/${payload}`,
		method: 'POST'
	});

export const loginService = (payload) =>
	sendRequest({
		url: `${API_AUTH_URL}login`,
		method: 'POST',
		body: payload
	});

export const forgotPasswordService = (payload) =>
	sendRequest({
		url: `${API_AUTH_URL}forget-password/generate`,
		method: 'POST',
		body: payload
	});

export const resetPasswordService = (payload) =>
	sendRequest({
		url: `${API_AUTH_URL}forget-password/confirm`,
		method: 'POST',
		body: payload
	});

export const oauthService = (payload) => {
	return sendRequest({
		url: `${API_AUTH_URL}oauth2/${payload.service}`,
		method: 'POST',
		body: payload.body
	});
};
export const userProfileService = () =>
	sendRequest({
		url: `${API_AUTH_URL}user/`,
		method: 'GET'
	});

export const userMetadataService = () =>
	sendRequest({
		url: `${API_AUTH_URL}user/metadata`,
		method: 'GET'
	});
export const userImageUpdateService = (fileData) =>
	sendRequest({
		url: `${API_AUTH_URL}user/`,
		method: 'PATCH',
		body: fileData,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
export const UpdateFullnameService = (payload) =>
	sendRequest({
		url: `${API_AUTH_URL}user/fullname`,
		method: 'PATCH',
		body: payload
	});
export const changePasswordService = (payload) =>
	sendRequest({
		url: `${API_AUTH_URL}change-password`,
		method: 'PATCH',
		body: payload
	});
export const logoutService = (payload) =>
	sendRequest({
		url: `${API_AUTH_URL}logout`,
		method: 'POST',
		body: payload
	});
export const deleteAccountRequestService = (payload) =>
	sendRequest({
		url: `${API_AUTH_URL}delete/request`,
		method: 'POST',
		body: payload
	});
export const deleteAccountCheckService = (payload) =>
	sendRequest({
		url: `${API_AUTH_URL}delete/check`,
		method: 'POST',
		body: payload
	});
export const deleteAccountConfirmService = (payload) =>
	sendRequest({
		url: `${API_AUTH_URL}delete/confirm`,
		method: 'POST',
		body: payload
	});
	
	export const billingAddressService = {
		GET: () =>
			sendRequest({
				url: `${API_AUTH_URL}user/billing-information`,
				method: 'GET'
			}),
		Post: (payload) =>
			sendRequest({
				url: `${API_AUTH_URL}user/billing-information`,
				method: 'Post',
				body: payload
			}),
		DELETE: () =>
			sendRequest({
				url: `${API_AUTH_URL}user/billing-information`,
				method: 'DELETE'
			})
	};