import React from 'react';

import { isUserLoggedIn } from 'utils/authentication';

// ** custom components
import DesktopMenuLoggedIn from './menutype/DesktopMenuLoggedIn';
import DesktopMenuGuest from './menutype/DesktopMenuGuest';
import DesktopMenuCompany from './menutype/DesktopMenuCompany';
import PromotionBanner from '@core/components/promotionbanner/PromotionBanner';

import classes from './DesktopnMenu.module.scss';


const NavigationMenu = ({ vipAccess, isB2B, newNotificationCount, hasSubscription, avalaibleTrialDays, promotion }) => {
	const isAuthenticated = isUserLoggedIn();
	return (
		<>
			<header className={`${classes['header-desktop']}`}>
				{!isB2B ? (
					<>
						{isAuthenticated &&
							<>
								<DesktopMenuLoggedIn
									vipAccess={vipAccess}
									newNotificationCount={newNotificationCount}
									hasSubscription={hasSubscription}
									avalaibleTrialDays={avalaibleTrialDays}
								/>
							</>
						}
						{!isAuthenticated && <DesktopMenuGuest />}
						{promotion && <PromotionBanner data={promotion} />}						
					</>
				) : (
					<DesktopMenuCompany />
				)}
			</header>
		</>
	);
};

export default NavigationMenu;
