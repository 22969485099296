import React from 'react';

import classes from './IconButton.module.scss';

const IconButton = ({ children, badge, className, ...rest }) => {
	const getBadge = () => {
		return badge > 9 ? '+9' : badge;
	};
	return (
		<button className={`${classes['icon-button-wrapper']} ${className || ''}`} {...rest}>
			{children}
			{!!badge && <p>{getBadge()}</p>}
		</button>
	);
};

export default IconButton;
