import React, { useState } from 'react';

// Toast Component
import Toast from '@core/components/toast/Toast';
import { useCallback } from 'react';

const UseToast = () => {
	const [show, setShow] = useState(false);
	const [type, setType] = useState('default');
	const [content, setContent] = useState('');
	const [time, setTime] = useState(5000);

	// Toast component wrapped in a Contatiner to be hanled by useToast
	const ToastContainer = useCallback(() => {
		return (
			<Toast show={show} showHandler={setShow} type={type} time={time}>
				{content}
			</Toast>
		);
	}, [content, show, type]);

	// toast Function to be called for TOASTING!!! :D
	const toast = (Newcontent, Newtype = undefined, Newtime = undefined) => {
		setShow(true);
		setContent(Newcontent);
		Newtype && setType(Newtype);
		Newtime && setTime(Newtime);
	};
	// useToast external APIs
	return { ToastContainer, toast };
};

export default UseToast;
