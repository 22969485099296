import React from 'react';

import { Snackbar } from '@mui/material';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';

// To be Rendered on the root of Dom
import { createPortal } from 'react-dom';

import useWindowSize from 'hooks/use-windowSize';

import CloseIcon from '@core/assets/icons/close-square.svg';

// Styles
import classes from './Toast.module.scss';

const Toast = ({ show, showHandler, children, type, time }) => {
	const { mobileDevice } = useWindowSize();

	function SlideTransition(props) {
		return <Slide {...props} direction={mobileDevice ? 'up' : 'down'} />;
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		showHandler(false);
	};

	if (typeof window === 'object') {
		return (
			<>
				{createPortal(
					<Snackbar
						open={show}
						autoHideDuration={time}
						onClose={handleClose}
						sx={{ mt: 8, mb: 7, width: '100%' }}
						TransitionComponent={SlideTransition}
						anchorOrigin={
							mobileDevice
								? { vertical: 'bottom', horizontal: 'center' }
								: { vertical: 'top', horizontal: 'center' }
						}>
						<Alert
							icon={false}
							data-testid="toast"
							className={`container
											 ${classes['toast-wrapper']} ${type && classes[type.toLowerCase() || '']}`}
							sx={{ backgroundColor: 'transparent', width: '100%', justifyContent: 'center' }}>
							{children}
							<CloseIcon
								data-testid="close-toast"
								role="button"
								onClick={() => showHandler(false)}
							/>
						</Alert>
					</Snackbar>,
					document?.getElementById('toast')
				)}
			</>
		);
	}
};

export default React.memo(Toast);
