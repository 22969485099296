import Axios from '@core/configs/axios';

import { getAccessToken } from 'utils/authentication';
// send Request function : wrapper for axios for more config
export const sendRequest = async ({
	auth = undefined, // send empty to send request without auth header
	url,
	params = {},
	method = 'GET',
	body = '',
	headers = {},
	userIp,
	responseType,
	...extraOptions
}) => {	
	let options = {
		url: url,
		method,
		responseType,
		headers: {
			platform: 'Web',
			'Content-Type': 'application/json',
			...headers
		},
		...extraOptions
	};
	 
	responseType !== undefined && (options.responseType == responseType);
	userIp !== undefined && (options.headers['x-forwarded-for'] = userIp);
	auth !== undefined && auth && (options.headers['Authorization'] = auth);
	auth === undefined &&
		getAccessToken() &&
		(options.headers['Authorization'] = `Bearer ${getAccessToken()}`);

	if (method !== 'GET') {
		options.data = body;
	}
	if (params !== {}) {
		options.params = params;
	}
	return Axios(options);
};
