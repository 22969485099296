import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CartStripeCheckout from './CartStripeCheckout';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CartStripe = ({ clientSecret, cart_id, returnUrl, invoice_amount, currency }) => {
	const options = {
		// passing the client secret obtained from the server
		clientSecret: clientSecret,
		appearance: {
			theme: 'stripe',
			variables: {
				colorPrimary: '#BB3A90',
				colorBackground: '#ffffff',
				colorText: '#0d0d0d',
				colorDanger: '#d20101',
				spacingUnit: '4px',
				borderRadius: '8px',
				spacingGridRow: '24px'
				// See all possible variables below
			}
		}
	};

	return (
		<Elements stripe={stripePromise} options={options}>
			<CartStripeCheckout
				cart_id={cart_id}
				clientSecret={clientSecret}
				returnUrl={returnUrl}
				invoice_amount={invoice_amount}
				currency={currency} />
		</Elements>
	);
};

export default CartStripe;
