import useMediaQuery from '@mui/material/useMediaQuery';

// Hook
const useWindowSize = () => {
	const mobileDevice = useMediaQuery('(max-width: 767px)');
	const landscapeMobileDevice = useMediaQuery('(min-width:450px) and (max-width: 767px)');
	const tabletDevice = useMediaQuery('(min-width:768px) and (max-width: 1199px)');
	const landscapeTabletDevice = useMediaQuery('(min-width:992px) and (max-width: 1199px)');
	const desktopDevice = useMediaQuery('(min-width:1200px)');
	return {
		mobileDevice,
		landscapeMobileDevice,
		tabletDevice,
		landscapeTabletDevice,
		desktopDevice
	};
};

export default useWindowSize;
