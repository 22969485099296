import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { isUserLoggedIn } from 'utils/authentication';
import { getCurrentRouteConfig } from 'utils/routerUtils';

const RouterGuard = () => {
	const router = useRouter();
	const loggedInUser = isUserLoggedIn();
	const nextPage = router?.state?.next || router?.query?.next;

	const getCurrentRoute = useCallback(() => {
		const currentRoute = getCurrentRouteConfig(router);
		return currentRoute;
	}, [router.pathname]);

	useEffect(() => {
		if (router.isReady) {
			const currentRoute = getCurrentRoute();
			if (currentRoute.deprecated)
				router.replace('/404');
			else
				if (loggedInUser) {
					if (currentRoute.meta.authentication) {
						// return user to homepage if is loggedin an tries to access auth routes
						nextPage ? router.replace(nextPage) : router.replace('/user-dashboard');
					}
				} else {
					if (currentRoute.meta.protected) {
						// return user to login page if tries to access a protected route without being authenticated
						router.replace('/login');
					}
				}
		}
	}, [router.pathname, getCurrentRoute, router.isReady]);
};

export default RouterGuard;
