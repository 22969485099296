import { useEffect } from 'react';

import useDeviceIdentify from './use-deviceidentify';

import useFcmToken from './use-fcmtoken';

const UseVisitor = () => {
	const { handleDeviceIdentification } = useDeviceIdentify();

	const { FetchFcmToken, retrieveToken } = useFcmToken();
	const fcmToken = retrieveToken();

	const isWindowAvailable = typeof window !== undefined;

	useEffect(() => {
		if (!fcmToken) {
			FetchFcmToken();
		}
	}, [isWindowAvailable]);

	useEffect(() => {
		if (fcmToken) {
			const deviceId = localStorage.getItem('deviceId');
			const deviceHasBeenIdentified = localStorage.getItem('deviceHasBeenIdentified');
			if (!deviceId) {
				if (typeof navigator !== 'undefined' && navigator) {
					const { DeviceUUID } = require('device-uuid');
					const uuid = new DeviceUUID().get();
					if (uuid) {
						localStorage.setItem('deviceId', uuid);
					}
				}
			}
			if (!deviceHasBeenIdentified) {
				handleDeviceIdentification();
			}
		}
	}, [fcmToken]);
};

export default UseVisitor;
