import { sendRequest } from './index';
import { API_COMPANY_URL } from '@core/constants/API';

export const companyInit = {
	GET: () =>
		sendRequest({
			url: `${API_COMPANY_URL}init`,
			method: 'GET'
		})
};

export const companyFirstStep = {
	GET: () =>
		sendRequest({
			url: `${API_COMPANY_URL}request/first-step`,
			method: 'GET'
		}),
	PATCH: (payload) =>
		sendRequest({
			url: `${API_COMPANY_URL}request/first-step`,
			method: 'PATCH',
			body: payload
		}),
	PUT: (payload) =>
		sendRequest({
			url: `${API_COMPANY_URL}request/first-step`,
			method: 'PUT',
			body: payload
		})
};

export const companyRequestRevoke = {
	DELETE: () =>
		sendRequest({
			url: `${API_COMPANY_URL}request`,
			method: 'DELETE'
		})
};

export const companyImages = {
	POST: (payload) =>
		sendRequest({
			url: `${API_COMPANY_URL}images`,
			method: 'POST',
			body: payload
		}),
	DELETE: (id) =>
		sendRequest({
			url: `${API_COMPANY_URL}images/${id}`,
			method: 'DELETE'
		})
};

export const companyCreateImages = {
	POST: (payload) =>
		sendRequest({
			url: `${API_COMPANY_URL}request/images`,
			method: 'POST',
			body: payload
		}),
	DELETE: (id) =>
		sendRequest({
			url: `${API_COMPANY_URL}request/images/${id}`,
			method: 'DELETE'
		})
};

export const companyLogoDashboard = {
	PATCH: (payload) =>
		sendRequest({
			url: `${API_COMPANY_URL}company-information`,
			method: 'PATCH',
			body: payload
		})
};

export const companySecondStep = {
	GET: () =>
		sendRequest({
			url: `${API_COMPANY_URL}request/second-step`,
			method: 'GET'
		}),
	PUT: (payload) =>
		sendRequest({
			url: `${API_COMPANY_URL}request/second-step`,
			method: 'PUT',
			body: payload
		})
};

export const companyInformationDashboard = {
	GET: () =>
		sendRequest({
			url: `${API_COMPANY_URL}company-information`,
			method: 'GET'
		}),
	PUT: (payload) =>
		sendRequest({
			url: `${API_COMPANY_URL}company-information`,
			method: 'PUT',
			body: payload
		})
};

export const companyBillingInformationDashboard = {
	GET: () =>
		sendRequest({
			url: `${API_COMPANY_URL}billing-information`,
			method: 'GET'
		}),
	PUT: (payload) =>
		sendRequest({
			url: `${API_COMPANY_URL}billing-information`,
			method: 'PUT',
			body: payload
		})
};

export const companyUserManagement = {
	GET: ({ page_size, page, searchKey }) =>
		sendRequest({
			url: `${API_COMPANY_URL}user-management?page_size=${page_size}&page=${page}${searchKey ? '&email=' + searchKey : ''
				}`,
			method: 'GET'
		}),
	POST: (payload) =>
		sendRequest({
			url: `${API_COMPANY_URL}user-management`,
			method: 'POST',
			body: payload
		}),
	PUT: ({ id, role }) =>
		sendRequest({
			url: `${API_COMPANY_URL}user-management/${id}`,
			method: 'PUT',
			body: { role: role }
		}),
	DELETE: ({ id }) =>
		sendRequest({
			url: `${API_COMPANY_URL}user-management/${id}`,
			method: 'DELETE'
		})
};

export const companyUserManagementResendInvitation = {
	POST: ({ id }) =>
		sendRequest({
			url: `${API_COMPANY_URL}user-management/${id}/resend-invitation`,
			method: 'POST'
		})
};

export const companyUserManagementAcceptInvitation = {
	POST: ({ id }) =>
		sendRequest({
			url: `${API_COMPANY_URL}user-management/${id}/accept-invitation`,
			method: 'POST'
		})
};

export const companyJobPostsMetaData = {
	GET: () =>
		sendRequest({
			url: `${API_COMPANY_URL}job-posts/metadata`,
			method: 'GET'
		})
};

export const companyJobPosts = {
	GET: ({ ...filters }) => {
		const queryParams = Object.keys(filters).reduce((searchparams, currentKey) => {
			if (filters[currentKey] !== '') {
				if (searchparams.length === 0) {
					return `?${currentKey}=${filters[currentKey]}`;
				} else {
					return searchparams + `&${currentKey}=${filters[currentKey]}`;
				}
			} else {
				return searchparams;
			}
		}, '');
		return sendRequest({
			url: `${API_COMPANY_URL}job-posts${queryParams}`,
			method: 'GET'
		});
	}
};

export const companyJobPost = {
	GET: (id) => {
		return sendRequest({
			url: `${API_COMPANY_URL}job-posts/${id}`,
			method: 'GET'
		});
	},
	POST: (payload) => {
		return sendRequest({
			url: `${API_COMPANY_URL}job-posts`,
			method: 'POST',
			body: { ...payload }
		});
	},
	PUT: ({ id, ...payload }) => {
		return sendRequest({
			url: `${API_COMPANY_URL}job-posts/${id}`,
			method: 'PUT',
			body: { ...payload }
		});
	},
	PATCH: ({ id, ...payload }) => {
		return sendRequest({
			url: `${API_COMPANY_URL}job-posts/${id}`,
			method: 'PATCH',
			body: { ...payload }
		});
	}
};

export const companyJobPostPublish = {
	POST: ({ id }) => {
		return sendRequest({
			url: `${API_COMPANY_URL}job-posts/${id}/publish`,
			method: 'POST',			
		});
	}
};

export const companyJobPostsLicenses = {
	GET: () =>
		sendRequest({
			url: `${API_COMPANY_URL}job-posts/licenses`,
			method: 'GET'
		}),
	Post: (body) =>
		sendRequest({
			url: `${API_COMPANY_URL}job-posts/licenses`,
			method: 'POST',
			body
		})
};

export const companyLicensesDiscount = {
	Post: (body) =>
		sendRequest({
			url: `${API_COMPANY_URL}discounts/validate`,
			method: 'POST',
			body
		})
};

export const companyB2C = {
	GET: (id) =>
		sendRequest({
			url: `${API_COMPANY_URL}companies/${id}`,
			method: 'GET'
		})
};

export const companyB2CJobs = {
	GET: (id) =>
		sendRequest({
			url: `${API_COMPANY_URL}companies/${id}/job-posts`,
			method: 'GET'
		})
};

export const companyLeave = {
	POST: () =>
		sendRequest({
			url: `${API_COMPANY_URL}leave`,
			method: 'POST'
		})
};

export const companyDashboardManagement = {
	GET: ({ page_size, page, searchKey }) =>
		sendRequest({
			url: `${API_COMPANY_URL}departments?page_size=${page_size}&page=${page}${searchKey ? '&name=' + searchKey : ''
				}`,
			method: 'GET'
		}),
	POST: (payload) =>
		sendRequest({
			url: `${API_COMPANY_URL}departments`,
			method: 'POST',
			body: payload
		}),
	PUT: ({ id, name }) =>
		sendRequest({
			url: `${API_COMPANY_URL}departments/${id}`,
			method: 'PUT',
			body: { name: name }
		}),
	DELETE: (id) =>
		sendRequest({
			url: `${API_COMPANY_URL}departments/${id}`,
			method: 'DELETE'
		})
};

export const companyDepartmentMemberManagment = {

	GET: (filters) => {
		const queryParams = Object.keys(filters).reduce((searchparams, currentKey) => {
			if (filters[currentKey] !== '') {
				if (searchparams.length === 0) {
					return `?${currentKey}=${filters[currentKey]}`;
				} else {
					return searchparams + `&${currentKey}=${filters[currentKey]}`;
				}
			} else {
				return searchparams;
			}
		}, '');
		return sendRequest({
			url: `${API_COMPANY_URL}departments/manageable-users${queryParams}`,
			method: 'GET'
		});
	},
	POST: ({ id, user_id }) =>
		sendRequest({
			url: `${API_COMPANY_URL}departments/${id}/department-members`,
			method: 'POST',
			body: { user_id: user_id }
		}),
	DELETE: (id) =>
		sendRequest({
			url: `${API_COMPANY_URL}department-members/${id}`,
			method: 'DELETE'
		})
};

export const companyDepartmentMemberList = {

	GET: ({ id, filters }) => {
		const queryParams = Object.keys(filters).reduce((searchparams, currentKey) => {
			if (filters[currentKey] !== '') {
				if (searchparams.length === 0) {
					return `?${currentKey}=${filters[currentKey]}`;
				} else {
					return searchparams + `&${currentKey}=${filters[currentKey]}`;
				}
			} else {
				return searchparams;
			}
		}, '');
		return sendRequest({
			url: `${API_COMPANY_URL}departments/${id}/department-members${queryParams}`,
			method: 'GET'
		});
	},
	POST: ({ id, user_id }) =>
		sendRequest({
			url: `${API_COMPANY_URL}departments/${id}/department-members`,
			method: 'POST',
			body: { user_id: user_id }
		}),
};

export const companyGetDepartmentMembers = {
	GET: ({ page_size, page, searchKey, id }) =>
		sendRequest({
			url: `${API_COMPANY_URL}departments/${id}/department-members?page_size=${page_size}&page=${page}${searchKey ? '&search_keyword=' + searchKey : ''}&has_exact_role=4`,
			method: 'GET'
		}),
};

export const companyDashboardMetaData = {
	GET: () =>
		sendRequest({
			url: `${API_COMPANY_URL}dashboard`,
			method: 'GET'
		}),
};

export const companyDashboardJobPostsData = {
	GET: (searchParams) =>
		sendRequest({
			url: `${API_COMPANY_URL}dashboard/job-posts${searchParams}`,
			method: 'GET'
		}),
};

export const companyJobPostApplicationsData = {
	GET: ({ queryParam, id }) =>
		sendRequest({
			url: `${API_COMPANY_URL}job-posts/${id}/applications${queryParam}`,
			method: 'GET'
		}),
};

export const companyJobPostApplicationsExport = {
	GET: ({ queryParam, id }) =>
		sendRequest({
			url: `${API_COMPANY_URL}job-posts/${id}/applications-exports${queryParam}`,
			method: 'GET'
		}),
};

export const companyDashboardJobPostMetaData = {
	GET: (id) =>
		sendRequest({
			url: `${API_COMPANY_URL}job-posts/${id}/applications-metadata`,
			method: 'GET'
		}),
};

export const companyDashboardJobPostApplicationDetail = {
	GET: (id) =>
		sendRequest({
			url: `${API_COMPANY_URL}applications/${id}`,
			method: 'GET'
		}),
	POST: ({ id, payload }) => {
		return sendRequest({
			url: `${API_COMPANY_URL}applications/${id}`,
			method: 'POST',
			body: payload,
		});
	}
};

export const companyDashboardJobPostApplicationDetailProfile = (id) => {
	return sendRequest({
		url: `${API_COMPANY_URL}applications/${id}/pharmuni-profiles`,
		method: 'GET',
	});
};

export const companyDashboardJobPostApplicationApplicantInfo = (id) => {
	return sendRequest({
		url: `${API_COMPANY_URL}applications/${id}/applicant-info`,
		method: 'GET',
	});
};

export const companyDashboardJobPostApplicationDetailCertificates = (payload) => {
	return sendRequest({
		url: `${API_COMPANY_URL}applications/${payload.id}/pharmuni-certificates${payload.searchParams}`,
		method: 'GET'
	});
}

export const companyDashboardJobPostApplicationDetailCertificatesLinks = (payload) => {
	return sendRequest({
		url: `${API_COMPANY_URL}applications/${payload.id}/pharmuni-certificates/${payload.certificate_id}/links`,
		method: 'GET'
	});
};

export const companyDashboardJobPostApplicationDetailStatuses = (id) => {
	return sendRequest({
		url: `${API_COMPANY_URL}applications/${id}/statuses`,
		method: 'GET'
	});
};

export const companyDashboardApplicationCoverLetter = (id) => {
	return sendRequest({
		url: `${API_COMPANY_URL}applications/${id}/cover-letters`,
		method: 'GET',
		responseType: 'arraybuffer',
	});
};

export const companyDashboardApplicationResume = (id) => {
	return sendRequest({
		url: `${API_COMPANY_URL}applications/${id}/resumes`,
		method: 'GET',
		responseType: 'arraybuffer',
	});
};

export const companyDashboardApplicationResumeStatus = (id) => {
	return sendRequest({
		url: `${API_COMPANY_URL}applications/${id}/resume-statuses`,
		method: 'GET',
	});
}

export const companyDashboardApplicationCoverLetterStatus = (id) => {
	return sendRequest({
		url: `${API_COMPANY_URL}applications/${id}/cover-letter-statuses`,
		method: 'GET',
	});
}

//messaging
export const companyJobPostApplicationMessagingMetaData = {
	GET: (id) =>
		sendRequest({
			url: `${API_COMPANY_URL}applications/${id}/messaging-metadata`,
			method: 'GET'
		}),
	PUT: ({id, active}) =>
		sendRequest({
			url: `${API_COMPANY_URL}applications/${id}/messaging-metadata`,
			method: 'PUT',
			body: { active: active }
		}),
};
export const companyStartJobPostApplicationMessaging = {
	POST: (id) =>
		sendRequest({
			url: `${API_COMPANY_URL}applications/${id}/messaging-metadata`,
			method: 'POST',
		})
};

export const companyMessagingService = {
	GET: ({ id, filters }) => {
		const queryParams = Object.keys(filters).reduce((searchparams, currentKey) => {
			if (filters[currentKey] !== '') {
				if (searchparams.length === 0) {
					return `?${currentKey}=${filters[currentKey]}`;
				} else {
					return searchparams + `&${currentKey}=${filters[currentKey]}`;
				}
			} else {
				return searchparams;
			}
		}, '');
		return sendRequest({
			url: `${API_COMPANY_URL}applications/${id}/messaging${queryParams}`,
			method: 'GET'
		});
	},
	POST: ({ id, message }) =>
		sendRequest({
			url: `${API_COMPANY_URL}applications/${id}/messaging`,
			method: 'POST',
			body: { message: message }
		}),
};
